import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AcceptRejectActivation, BidNotificationInfo } from '../models';
import { AcceptRejectActivationService, BidNotificationService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class BmDataAccessFacade {
  constructor(
    private bidNotificationService: BidNotificationService,
    private acceptRejectActivationService: AcceptRejectActivationService
  ) {}

  /**
   *
   * @param bidId
   */
  getBidNotificationInfo(bidId: number): Observable<BidNotificationInfo> {
    return this.bidNotificationService.getBidNotificationInfo(bidId);
  }

  /**
   *
   * @param acceptRejectActivation
   */
  acceptRejectActivation(acceptRejectActivation: AcceptRejectActivation): Observable<AcceptRejectActivation> {
    return this.acceptRejectActivationService.acceptRejectActivation(acceptRejectActivation);
  }
}
