import { Injector } from '@angular/core';

import { AudioService } from '../services';

const injector = Injector.create({ providers: [{ provide: AudioService, deps: [] }] });

export const playSoundDecorator = (soundToPlay: string): MethodDecorator =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/ban-types
  (() => (target: Function, key: string, descriptor: any) => {
    const originalMethod = descriptor.value;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    descriptor.value = function (...args: any[]) {
      const result = originalMethod.apply(this, args);

      const audioService = injector.get(AudioService);

      audioService.playSound(soundToPlay);

      return result;
    };

    return descriptor;
  })();
