import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { DateTimeHelperService } from '@nexweb/shared/date-time-helper';
import { isNullOrUndefined } from '@nexweb/util';

@Injectable({
  providedIn: 'root',
})
export class ApiJsonDateParserInterceptor implements HttpInterceptor {
  //only process api calls to nexbase api's
  private readonly urlWhiteList = [
    'api/Aum',
    'api/Amt',
    'api/app',
    'api/Bm',
    'api/cal',
    'api/Cmt',
    'api/Emmd',
    'api/Cmt',
    'api/Scheduler',
    'api/Mdd',
    'api/Setrep',
    'api/samm',
    'api/Tou',
    'api/Tsam',
    'api/Txmdms',
  ] as string[];

  constructor(private dateHelperService: DateTimeHelperService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let modifiedRequest: HttpRequest<unknown>;
    //exclude non NexBase module api urls
    if (this.isUrlWhitelisted(request)) {
      //clone the request and modify the HttpParams
      //before sending to the server
      modifiedRequest = request.clone({
        params: this.convertHttpParamsDateStringsToJsonIsoStrings(request.params),
        body: this.convertHttpBodyDatesToJsonIsoStrings(request.body),
      });
    } else {
      modifiedRequest = request.clone();
    }

    return next.handle(modifiedRequest).pipe(
      filter((event) => event instanceof HttpResponse),
      map((event: HttpResponse<unknown>) => {
        //modify the HttpResponse body before
        //sending on to the components
        if (this.isUrlWhitelisted(modifiedRequest)) {
          return event.clone({
            body: this.convertHttpResponseBodyJsonIsoStringsToDates(event.body),
          });
        } else {
          return event;
        }
      })
    );
  }

  /**
   * Loops through the HttpResponse body and
   * converts all Json Iso string values to
   * equivalent javascript dates
   * @returns
   * @param body
   */
  private convertHttpResponseBodyJsonIsoStringsToDates(body: unknown) {
    return this.dateHelperService.convertJsonIsoStringsToDate(body);
  }

  /**
   * Loops through the HttpParams and
   * converts all javascript Date objects
   * to the equivalent Json Iso string value
   * @param httpParams
   * @returns a new HttpParams object
   */
  private convertHttpParamsDateStringsToJsonIsoStrings(httpParams: HttpParams): HttpParams {
    if (httpParams === null || httpParams === undefined) {
      return httpParams;
    }

    const clonedParams = {};

    for (const key of httpParams.keys()) {
      let paramValues = httpParams.getAll(key);

      paramValues = this.dateHelperService.convertDateStringsToJsonIsoStrings(paramValues) as string[];

      if (isNullOrUndefined(paramValues)) {
        clonedParams[key] = httpParams.getAll(key);
      } else {
        clonedParams[key] = paramValues;
      }
    }

    return new HttpParams({ fromObject: clonedParams });
  }

  /**
   * Loop through the HttpRequest body and
   * converts all Date values to equivalent
   * Json Iso string value
   * @param httpBody
   * @returns
   */
  private convertHttpBodyDatesToJsonIsoStrings(httpBody: unknown): unknown {
    return this.dateHelperService.convertDateStringsToJsonIsoStrings(httpBody);
  }

  private isUrlWhitelisted(request: HttpRequest<unknown>) {
    return this.urlWhiteList.some((url) => request.url.toLowerCase().includes(url.toLowerCase()));
  }
}
