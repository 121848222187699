import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { ToasterService } from '@abp/ng.theme.shared';
import { ChatConfigService } from '@volo/abp.ng.chat/config';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'nexweb-chat-icon',
  templateUrl: './chat-icon.component.html',
  styleUrls: ['./chat-icon.component.scss'],
})
export class ChatIconComponent implements OnDestroy {
  subscription: Subscription;

  constructor(public chatConfigService: ChatConfigService, private toaster: ToasterService, private router: Router) {
    this.chatConfigService = chatConfigService;
    this.toaster = toaster;
    this.router = router;
    this.subscription = new Subscription();

    this.listenToMessages();
    this.listenToRouterEvents();
  }

  public listenToMessages() {
    this.subscription.add(
      this.chatConfigService.message$.pipe(filter(() => this.router.url !== '/chat')).subscribe((message) => {
        const { senderName, senderSurname, senderUsername, text } = message;
        const sender = `${senderName ? senderName + (' ' + senderSurname || '') : senderUsername}`;
        const toasterMessage = `<strong>${sender}</strong>: ${text.length > 50 ? text.substring(0, 49) + '...' : text}`;
        this.chatConfigService.addUnreadMessageCount(1);
        this.toaster.info(toasterMessage, 'Chat::NewChatMessage', {
          tapToDismiss: true,
        });
      })
    );
  }
  public listenToRouterEvents() {
    this.subscription.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd && event.url === '/chat'))
        .subscribe(() => this.chatConfigService.resetUnreadMessageCount())
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
