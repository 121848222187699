<form [formGroup]="responseFormGroup">
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.instrument }}">
        <input kendoTextBox formControlName="instrument" class="k-input-full" [value]="response.instrument" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.requestingTsoPortfolio }}">
        <input
          kendoTextBox
          formControlName="requestingTsoPortfolio"
          class="k-input-full"
          [value]="response.requestingTsoPortfolio" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.supplyingBspPortfolio }}">
        <input
          kendoTextBox
          formControlName="supplyingBspPortfolio"
          class="k-input-full"
          [value]="response.supplyingBspPortfolio" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.requestedQuantity }}">
        <input
          kendoTextBox
          formControlName="requestedQuantity"
          class="k-input-full"
          [value]="response.requestedQuantity" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.defaultFullDispatchTime }}">
        <kendo-datetimepicker
          formControlName="defaultFullDispatchTime"
          [format]="dateTimeFormat"
          class="k-input-full"
          [value]="response.defaultFullDispatchTime"></kendo-datetimepicker>
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.confirmedFullDispatchTime }}">
        <kendo-datetimepicker
          formControlName="confirmedFullDispatchTime"
          [format]="dateTimeFormat"
          class="k-input-full"
          [value]="response.confirmedFullDispatchTime"></kendo-datetimepicker>
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.activationRequestAccepted }}">
        <input
          type="checkbox"
          kendoCheckBox
          formControlName="activationRequestAccepted"
          [value]="response.activationRequestAccepted"
          (change)="onCheckboxChange()" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.reasonForDeclining }}">
        <textarea
          kendoTextArea
          formControlName="reasonForDeclining"
          rows="4"
          [autoSize]="true"
          class="k-input-full"
          [value]="response.reasonForDeclining"></textarea>
        <div *ngIf="reasonForDeclining.errors && reasonForDeclining.errors.required" class="error-message">
          {{ requiredFieldError }}
        </div>
        <div *ngIf="reasonForDeclining.errors && reasonForDeclining.errors.minlength" class="error-message">
          {{ minLengthError }}
        </div>
        <div *ngIf="reasonForDeclining.errors && reasonForDeclining.errors.maxlength" class="error-message">
          {{ maxLengthError }}
        </div>
      </kendo-floatinglabel>
    </div>
  </div>
</form>
