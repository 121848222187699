import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BidNotificationInfo, BmDataAccessFacade } from '@nexweb/shared/data-access/bm';

import { RequestActivation } from '../../../models';
import { RequestActivationService } from '../../../services/request-activation.service';

@Component({
  selector: 'nexweb-supplying-bsp-notification',
  templateUrl: './supplying-bsp-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplyingBspNotificationComponent implements OnInit {
  notification$: Observable<RequestActivation.ActivationRequestNotification>;
  @Input() bidId: number;
  mustAcknowledgeMessageRead: boolean;
  labels: RequestActivation.NotificationLabels;
  headings: RequestActivation.NotificationHeadings;
  dateTimeFormat: string;

  constructor(private requestActivationService: RequestActivationService, private bmFacade: BmDataAccessFacade) {}

  ngOnInit(): void {
    this.labels = this.requestActivationService.getNotificationLabels();
    this.headings = this.requestActivationService.getNotificationHeadings();
    this.dateTimeFormat = this.requestActivationService.getDateTimeFormat();

    this.initialiseData();
  }

  private initialiseData(): void {
    this.notification$ = this.bmFacade.getBidNotificationInfo(this.bidId).pipe(
      map((bidNotificationInfo: BidNotificationInfo) => {
        const bidNotificationData = {
          name: bidNotificationInfo.requestingTsoName,
          telephoneNumber: bidNotificationInfo.requestingTsoPhoneNumber,
          portfolio: bidNotificationInfo.requestingTsoPortfolioName,
          currentInstrument: {
            instrument: bidNotificationInfo.instrument,
            requestedQuantity: bidNotificationInfo.requestedQuantity,
            requestedActivationTime: bidNotificationInfo.requestFullDispatchTime,
            earlyActivationRequested: bidNotificationInfo.earlyActivation,
          } as RequestActivation.NotificationCurrentInstrument,
          subsequentInstrument: {
            instrument: bidNotificationInfo.subsequentInstrument,
            requestedQuantity: bidNotificationInfo.subsequentRequestedQuantity,
            requestedActivationTime: bidNotificationInfo.subsequentDefaultFullDispatchTime,
          } as RequestActivation.NotificationSubsequentInstrument,
        } as RequestActivation.ActivationRequestNotification;

        return bidNotificationData;
      })
    );
  }
}
