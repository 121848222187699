import { APP_INITIALIZER } from '@angular/core';

import { KgwConfigService } from '../services/kgw-config.service';

export const KGW_CONFIG_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    deps: [KgwConfigService],
    multi: true,
    useFactory: (kgwConfigService: KgwConfigService) => () => kgwConfigService.loadKgwConfig$(),
  },
];
