import { Injectable } from '@angular/core';

import { Rest, RestService } from '@abp/ng.core';
import { Observable } from 'rxjs';

import { SammApplication, SammSolutionRole } from '../models/';

@Injectable({
  providedIn: 'root',
})
export class SammApplicationService {
  private apiUrl = `/api/Samm/samm-lookup`;

  constructor(private rest: RestService) {}

  /**
   *
   * @param params
   * @returns
   */
  public getSammApplications(includeAllOption?: boolean): Observable<SammApplication[]> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.apiUrl}/samm-applications`,
      params: { includeAllOption },
    };

    return this.rest.request<null, SammApplication[]>(request);
  }

  public getServerTime(): Observable<string> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.apiUrl}/server-time`,
    };

    return this.rest.request<null, string>(request);
  }

  public getSammSolutionRoles$(includeAllOption?: boolean): Observable<SammSolutionRole[]> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.apiUrl}/samm-solution-roles`,
      params: { includeAllOption },
    };

    return this.rest.request<null, SammSolutionRole[]>(request);
  }
}
