import { NavItem, NavItemsService } from '@abp/ng.theme.shared';
import { Component, TrackByFunction } from '@angular/core';
import { isNullOrUndefined } from '@nexweb/util';
import { Observable } from 'rxjs';
import { ChatIconComponent } from '../chat-icon/chat-icon.component';
import { EnvironmentIndicatorComponent } from '../environment-indicator/environment-indicator.component';
import { LanguagesComponent } from '../languages/languages.component';
import { MessageInboxComponent } from '../message-inbox/message-inbox.component';
import { SystemDetailsComponent } from '../system-details/system-details.component';

@Component({
  selector: 'nexweb-nav-items',
  templateUrl: './nav-items.component.html',
})
export class NavItemsComponent {
  private readonly _systemDetailsId = 'SystemDetailsComponent';
  private readonly _messageInboxId = 'MessageInboxComponent';
  private readonly _chatIconId = 'Chat.ChatIconComponent';
  private readonly _languagesId = 'Theme.LanguagesComponent';
  private readonly _environmentIndicatorId = 'EnvironmentIndicatorComponent';

  private chatNavItem: NavItem = {
    id: this._chatIconId,
    component: ChatIconComponent,
  };

  private systemDetailsNavItem: NavItem = {
    id: this._systemDetailsId,
    component: SystemDetailsComponent,
    order: 98,
  };

  private messageInboxNavItem: NavItem = {
    id: this._messageInboxId,
    component: MessageInboxComponent,
    order: 99.99,
  };

  private languagesNavItemComponent: NavItem = {
    id: this._languagesId,
    component: LanguagesComponent,
  };

  private environmentIndicatorNavItem: NavItem = {
    id: this._environmentIndicatorId,
    component: EnvironmentIndicatorComponent,
    order: 100,
  };

  trackByFn: TrackByFunction<NavItem> = (_, element) => element?.id;

  constructor(private readonly navItemService: NavItemsService) {
    this.navItemService.patchItem(this._chatIconId, this.chatNavItem);
    this.navItemService.patchItem(
      this._languagesId,
      this.languagesNavItemComponent
    );

    const environmentIndicator = this.navItemService.items.find((x) => x.id === this._environmentIndicatorId);

    if (isNullOrUndefined(environmentIndicator)) {
      this.navItemService.addItems([this.environmentIndicatorNavItem]);
    }

    const sysDetails = this.navItemService.items.find(
      (x) => x.id === this._systemDetailsId
    );

    if (isNullOrUndefined(sysDetails)) {
      this.navItemService.addItems([this.systemDetailsNavItem]);
    }

    const messageInbox = this.navItemService.items.find(
      (x) => x.id === this._messageInboxId
    );

    if (isNullOrUndefined(messageInbox)) {
      this.navItemService.addItems([this.messageInboxNavItem]);
    }
  }

  public getNavItems$(): Observable<NavItem[]> {
    return this.navItemService.items$;
  }
}
