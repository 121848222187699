import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule as AbpCoreModule } from '@abp/ng.core';
import { FeatureManagementModule } from '@abp/ng.feature-management';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { CommercialUiConfigModule } from '@volo/abp.commercial.ng.ui/config';
import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { AuditLoggingConfigModule } from '@volo/abp.ng.audit-logging/config';
import { ChatConfigModule } from '@volo/abp.ng.chat/config';
import { FileManagementConfigModule } from '@volo/abp.ng.file-management/config';
import { IdentityConfigModule } from '@volo/abp.ng.identity/config';
import { LanguageManagementConfigModule } from '@volo/abp.ng.language-management/config';
import { registerLocale } from '@volo/abp.ng.language-management/locale';
import { OpeniddictproConfigModule } from '@volo/abp.ng.openiddictpro/config';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { TextTemplateManagementConfigModule } from '@volo/abp.ng.text-template-management/config';
import { HttpErrorComponent, ThemeLeptonModule } from '@volo/abp.ng.theme.lepton';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { AmtMenuEffects } from '@nexweb/amt/effects';
import { BmMenuEffects } from '@nexweb/bm/effects';
import { BulkDownloadEffects } from '@nexweb/bulk-download/effects';
import { CmtMenuEffects } from '@nexweb/cmt/effects';
import { SetrepMenuEffects } from '@nexweb/setrep/effects';
import { EffectsModule } from '@nexweb/shared/effects';
import { SharedInterceptorsApiJsonDateParserModule } from '@nexweb/shared/interceptors/api-json-date-parser';
import { SharedInterceptorsConnectTokenRequestModule } from '@nexweb/shared/interceptors/connect-token-request';
import { SharedLoadingModule } from '@nexweb/shared/loading';
import { SharedSystemMessagingModule } from '@nexweb/shared/system-messaging';
import { API_URL_TOKEN } from '@nexweb/shared/tokens/api-url';
import { KGW_CONFIG_PROVIDER } from '@nexweb/shared/ui/kgw/config';
import { SharedUserIdleModule } from '@nexweb/shared/ui/user-idle';
import { MsMenuEffects } from '@nexweb/smas/effects';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';

const LOGGERS = [NgxsLoggerPluginModule.forRoot({ disabled: false })];

@NgModule({
  imports: [
    DialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AbpCoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    ThemeSharedModule.forRoot({
      httpErrorConfig: {
        errorScreen: {
          component: HttpErrorComponent,
          forWhichErrors: [401, 403, 404, 500],
          hideCloseIcon: true,
        },
      },
    }),
    AbpOAuthModule.forRoot(),
    AccountAdminConfigModule.forRoot(),
    AccountPublicConfigModule.forRoot(),
    IdentityConfigModule.forRoot(),
    LanguageManagementConfigModule.forRoot(),
    SaasConfigModule.forRoot(),
    AuditLoggingConfigModule.forRoot(),
    OpeniddictproConfigModule.forRoot(),
    TextTemplateManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ThemeLeptonModule.forRoot(),
    CommercialUiConfigModule.forRoot(),
    ChatConfigModule.forRoot(),
    FileManagementConfigModule.forRoot(),
    FeatureManagementModule.forRoot(),
    NgxsModule.forRoot([]),
    NgxsFormPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    ...(environment.production ? [] : LOGGERS),
    SharedUserIdleModule.forRoot(),
    BsDropdownModule.forRoot(),
    SharedSystemMessagingModule.forRoot(),
    SharedLoadingModule.forRoot(),
    SharedInterceptorsApiJsonDateParserModule.forRoot(),
    SharedInterceptorsConnectTokenRequestModule.forRoot(),
    EffectsModule.register([
      AmtMenuEffects,
      BmMenuEffects,
      CmtMenuEffects,
      SetrepMenuEffects,
      MsMenuEffects,
      BulkDownloadEffects,
    ]),
  ],
  declarations: [AppComponent],
  providers: [
    APP_ROUTE_PROVIDER,
    KGW_CONFIG_PROVIDER,
    { provide: API_URL_TOKEN, useValue: environment.apis.default.url },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
