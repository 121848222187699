import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { BidNotificationInfo, BmDataAccessFacade } from '@nexweb/shared/data-access/bm';

import { RespondToActivationRequest } from '../../../models';
import { RespondToActivationRequestService } from '../../../services/respond-to-activation-request.service';

@Component({
  selector: 'nexweb-tso',
  templateUrl: './tso.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsoComponent implements OnInit {
  response$: Observable<RespondToActivationRequest.ActivationRequestResponse>;
  @Input() bidId: number;
  labels: RespondToActivationRequest.ResponseLabels;
  dateTimeFormat: string;
  accepted: boolean;
  subsequentBidAccepted: boolean;
  rejectReason: string;
  subsequentRejectReason: string;
  concurrencyStamp: string;
  isFormValid = true;

  constructor(
    private respondToActivationRequestService: RespondToActivationRequestService,
    private bmFacade: BmDataAccessFacade
  ) {}

  ngOnInit(): void {
    this.labels = this.respondToActivationRequestService.getTsoResponseLabels();
    this.dateTimeFormat = this.respondToActivationRequestService.getDateTimeFormat();

    this.initialiseData();
  }

  private initialiseData(): void {
    this.response$ = this.bmFacade.getBidNotificationInfo(this.bidId).pipe(
      map((bidNotificationInfo: BidNotificationInfo) => {
        const bidNotificationData = {
          concurrencyStamp: bidNotificationInfo.concurrencyStamp,
          maxResponseTime: bidNotificationInfo.maxResponseTime,
          currentInstrument: {
            instrument: bidNotificationInfo.instrument,
            requestingTsoPortfolio: bidNotificationInfo.requestingTsoPortfolioName,
            supplyingBspPortfolio: bidNotificationInfo.supplyingBspPortfolioName,
            requestedQuantity: bidNotificationInfo.requestedQuantity
              ? bidNotificationInfo.requestedQuantity.toString()
              : ' ',
            defaultFullDispatchTime: bidNotificationInfo.defaultFullDispatchTime,
            confirmedFullDispatchTime: bidNotificationInfo.requestFullDispatchTime,
            activationRequestAccepted: true,
            reasonForDeclining: bidNotificationInfo.rejectReason,
            earlyActivationRequested: bidNotificationInfo.earlyActivation,
            requestedFullDispatchTime: bidNotificationInfo.requestFullDispatchTime,
          } as RespondToActivationRequest.ResponseCurrentInstrument,
          subsequentInstrument: {
            instrument: bidNotificationInfo.subsequentInstrument,
            requestingTsoPortfolio: bidNotificationInfo.requestingTsoPortfolioName,
            supplyingBspPortfolio: bidNotificationInfo.supplyingBspPortfolioName,
            requestedQuantity: bidNotificationInfo.subsequentRequestedQuantity
              ? bidNotificationInfo.subsequentRequestedQuantity.toString()
              : ' ',
            defaultFullDispatchTime: bidNotificationInfo.subsequentDefaultFullDispatchTime,
            confirmedFullDispatchTime: bidNotificationInfo.subsequentDefaultFullDispatchTime,
            activationRequestAccepted: true,
            reasonForDeclining: bidNotificationInfo.rejectReason,
          } as RespondToActivationRequest.ResponseInstrument,
        } as RespondToActivationRequest.ActivationRequestResponse;

        return bidNotificationData;
      })
    );
  }

  formValuesChanged(event) {
    if (event.currentActivationRequestAccepted instanceof Object) {
      this.accepted = event.currentActivationRequestAccepted.value;
    } else {
      this.accepted = event.currentActivationRequestAccepted;
    }

    if (event.currentReasonForDeclining) {
      this.rejectReason = event.currentReasonForDeclining;
    }

    if (event.subsequentActivationRequestAccepted instanceof Object) {
      this.subsequentBidAccepted = event.subsequentActivationRequestAccepted.value;
    } else {
      this.subsequentBidAccepted = event.subsequentActivationRequestAccepted;
    }

    if (event.subsequentReasonForDeclining) {
      this.subsequentRejectReason = event.subsequentReasonForDeclining;
    }

    if (event.concurrencyStamp) {
      this.concurrencyStamp = event.concurrencyStamp;
    }
  }

  formValidationChanged(event) {
    this.isFormValid = event;
  }
}
