import { Environment } from '@abp/ng.core';

const baseUrl = '#{AngularUrl}#';

export const environment = {
  production: true,
  application: {
    baseUrl,
    name: '#{ApplicationName}#',
  },
  oAuthConfig: {
    issuer: '#{OAuthConfigIssuer}#',
    redirectUri: baseUrl,
    clientId: '#{OAuthConfigClientId}#',
    scope: '#{OAuthConfigScope}#',
    showDebugInformation: false,
    oidc: false,
    requireHttps: true,
  },
  apis: {
    default: {
      url: '#{ApiUrl}#',
    },
  },
  localization: {
    defaultResourceName: '#{DefaultResourceName}#',
  },
} as Environment;
