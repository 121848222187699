import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { mtpRoutes } from '@nexweb/mtp/mtp-config';

@NgModule({
  imports: [RouterModule.forRoot(mtpRoutes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
