<form [formGroup]="deactivationFormGroup">
  <div>
    <span>
      <h5>{{ headings.deactivationInstruction }}</h5>
    </span>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.name }}">
        <input kendoTextBox formControlName="name" class="k-input-full" [value]="notification.tsoName" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.telephoneNumber }}">
        <input
          kendoTextBox
          formControlName="telephoneNumber"
          class="k-input-full"
          [value]="notification.tsoTelephoneNumber" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.portfolio }}">
        <input kendoTextBox formControlName="portfolio" class="k-input-full" [value]="notification.tsoPortfolio" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div>
    <span>
      <h5>{{ headings.details }}</h5>
    </span>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.quantity }}">
        <input kendoTextBox formControlName="quantity" class="k-input-full" [value]="notification.orderQuantity" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.confirmedFullDispatchTime }}">
        <kendo-datetimepicker
          formControlName="confirmedFullDispatchTime"
          [format]="dateTimeFormat"
          class="k-input-full"
          [value]="notification.confirmedFullDispatchTime"></kendo-datetimepicker>
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.fullyDeactivatedTime }}">
        <kendo-datetimepicker
          formControlName="fullyDeactivatedTime"
          [format]="dateTimeFormat"
          class="k-input-full"
          [value]="notification.fullyDeactivatedTime"></kendo-datetimepicker>
      </kendo-floatinglabel>
    </div>
  </div>
  <!--<div class="space10"></div>
      <div class="form-group input-group text-right">
      <div class="col-md-12">
        <nexweb-acknowledge-message-read
          formControlName="acknowledgeMessageRead"
          (change)="onCheckboxChange($event)"
          [acknowledgementMessage]="acknowledgementMessage"
        ></nexweb-acknowledge-message-read>
      </div>
    </div> -->
</form>
