import { APP_INITIALIZER } from '@angular/core';

import { RoutesService } from '@abp/ng.core';
import { eFileManagementRouteNames } from '@volo/abp.ng.file-management/common';

import { mtpRouteProviderRoutes } from '@nexweb/mtp/mtp-config';

export const APP_ROUTE_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    useFactory: configureRoutes,
    deps: [RoutesService],
    multi: true,
  },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add(mtpRouteProviderRoutes);
    //remove File Management from Root of menu so that it can be added
    //to own custom routes within our own modules
    routes.remove([eFileManagementRouteNames.FileManagement]);
  };
}
