import { Injectable } from '@angular/core';

import { Rest, RestService } from '@abp/ng.core';
import { Observable } from 'rxjs/internal/Observable';

import { AcceptRejectActivation } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AcceptRejectActivationService {
  private baseApiUrl = '/api/Bm/bm-bid-activation';

  constructor(private rest: RestService) {}

  /**
   *
   * @param bidId
   */
  acceptRejectActivation(body: AcceptRejectActivation): Observable<AcceptRejectActivation> {
    const request: Rest.Request<AcceptRejectActivation> = {
      method: 'POST',
      url: `${this.baseApiUrl}/accept-reject-activation`,
      body: Object.assign({}, body),
    };

    return this.rest.request<AcceptRejectActivation, AcceptRejectActivation>(request);
  }
}
