import { Injectable } from '@angular/core';

import { RoutesService, isNullOrUndefined } from '@abp/ng.core';
import { ConfigStateService } from '@abp/ng.core';
import { tap } from 'rxjs/operators';

import { createEffect } from '@nexweb/shared/effects';
import { FeatureFlagNames } from '@nexweb/shared/guards/feature-flag';

import { balancingMarketMenuName } from './constants';

@Injectable({ providedIn: 'root' })
export class BmMenuEffects {
  bmMenuEffect$ = createEffect(() =>
    this.configStateService.getFeature$(FeatureFlagNames.balancingMarketEnabled).pipe(
      tap((featureFlag) => {
        if (!isNullOrUndefined(featureFlag)) {
          const featureValue = JSON.parse(featureFlag);

          this.routes.patch(balancingMarketMenuName, {
            invisible: !featureValue,
          });
        }
      })
    )
  );

  constructor(private configStateService: ConfigStateService, private routes: RoutesService) {}
}
