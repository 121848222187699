export function conditionalValidator(predicate, validator) {
  return (formControl) => {
    if (!formControl.parent) {
      return null;
    }

    if (predicate()) {
      return validator(formControl);
    }

    return null;
  };
}

//Usage example

// this.responseFormGroup = new FormGroup({
//     myCheckBox: new FormControl({
//         value: true,
//       }),
//     myTextField: new FormControl(
//       {
//         value: '',
//         disabled: true,
//       },
//       [
//         conditionalValidator(
//           () =>
//             !this.responseFormGroup.get('myCheckBox').value,
//                 Validators.compose([
//                     Validators.required,
//                     Validators.minLength(50),
//                     Validators.maxLength(100),
//                 ])
//         ),
//       ]
//     )
// });
