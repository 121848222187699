import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { IssueDeactivationInstruction } from '../../../models';

@Component({
  selector: 'nexweb-deactivation-confirmation-form',
  templateUrl: './deactivation-confirmation-form.component.html',
})
export class DeactivationConfirmationFormComponent implements OnInit {
  @Input() notification: IssueDeactivationInstruction.DeactivationInstruction;
  @Input() labels: IssueDeactivationInstruction.Labels;
  @Input() headings: IssueDeactivationInstruction.Headings;
  @Input() dateTimeFormat: string;

  deactivationFormGroup: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.initialise();
  }

  public initialise(): void {
    this.deactivationFormGroup = new FormGroup({
      name: new FormControl({
        value: this.notification.supplyingTsoName,
        disabled: true,
      }),
      telephoneNumber: new FormControl({
        value: this.notification.supplyingTsoTelephoneNumber,
        disabled: true,
      }),
      portfolio: new FormControl({
        value: this.notification.bspPortfolio,
        disabled: true,
      }),
      quantity: new FormControl({
        value: this.notification.orderQuantity,
        disabled: true,
      }),
      confirmedFullDispatchTime: new FormControl({
        value: this.notification.confirmedFullDispatchTime,
        disabled: true,
      }),
      fullyDeactivatedTime: new FormControl({
        value: this.notification.fullyDeactivatedTime,
        disabled: true,
      }),
      // acknowledgeMessageRead: new FormControl({
      //   value: false,
      // }),
    });
  }
}
