import { Component, OnInit } from '@angular/core';

import { ConfigStateService } from '@abp/ng.core';

import { isNullOrUndefined } from '@nexweb/util';

@Component({
  selector: 'nexweb-message-inbox',
  templateUrl: './message-inbox.component.html',
})
export class MessageInboxComponent implements OnInit {
  public enableMessageInbox: boolean;
  public currentUser$ = this.configStateService.getOne$('currentUser');

  constructor(private configStateService: ConfigStateService) {}

  ngOnInit(): void {
    if (!isNullOrUndefined(this.configStateService.getSetting('Mdd.EnableMessageInbox'))) {
      this.enableMessageInbox = JSON.parse(this.configStateService.getSetting('Mdd.EnableMessageInbox'));
    }
  }
}
