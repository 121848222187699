import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { BidNotificationInfo, BmDataAccessFacade } from '@nexweb/shared/data-access/bm';

import { IssueDeactivationInstruction } from '../../../models';
import { IssueDeactivationInstructionService } from '../../../services/issue-deactivation-instruction.service';

@Component({
  selector: 'nexweb-deactivation-instructing-tso-confirmation',
  templateUrl: './deact-instruct-tso-conf.component.html',
})
export class DeactivationInstructingTsoConfirmationComponent implements OnInit {
  @Input() bidId: number;

  notification$: Observable<IssueDeactivationInstruction.DeactivationInstruction>;

  dateTimeFormat: string;
  labels: IssueDeactivationInstruction.Labels;

  headings: IssueDeactivationInstruction.Headings;

  constructor(
    private bmFacade: BmDataAccessFacade,
    private issueDeactivationInstructionService: IssueDeactivationInstructionService
  ) {}

  ngOnInit(): void {
    this.labels = this.issueDeactivationInstructionService.getConfirmationLabels();
    this.headings = this.issueDeactivationInstructionService.getConfirmationHeadings();
    this.dateTimeFormat = this.issueDeactivationInstructionService.getDateTimeFormat();

    this.initialiseData();
  }

  private initialiseData(): void {
    this.notification$ = this.bmFacade.getBidNotificationInfo(this.bidId).pipe(
      map((bidNotificationInfo: BidNotificationInfo) => {
        const bidNotificationData = {
          supplyingTsoName: bidNotificationInfo.supplyingTsoName,
          supplyingTsoTelephoneNumber: bidNotificationInfo.supplyingTsoPhoneNumber,
          bspPortfolio: bidNotificationInfo.supplyingBspPortfolioName,
          tsoName: bidNotificationInfo.requestingTsoName,
          tsoTelephoneNumber: bidNotificationInfo.requestingTsoPhoneNumber,
          tsoPortfolio: bidNotificationInfo.requestingTsoName,
          orderQuantity: bidNotificationInfo.requestedQuantity,
          confirmedFullDispatchTime: bidNotificationInfo.confirmedFullDispatchTime,
          fullyDeactivatedTime: bidNotificationInfo.deactivationDispatchTime,
        } as IssueDeactivationInstruction.DeactivationInstruction;

        return bidNotificationData;
      })
    );
  }
}
