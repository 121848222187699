import { Component } from '@angular/core';

import { ConfigStateService, LanguageInfo, SessionStateService } from '@abp/ng.core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'nexweb-languages',
  templateUrl: './languages.component.html',
})
export class LanguagesComponent {
  public languages$: Observable<LanguageInfo[]> = this.configStateService.getDeep$('localization.languages');

  public currentUser$ = this.configStateService.getOne$('currentUser');

  constructor(private configStateService: ConfigStateService, private sessionState: SessionStateService) {}

  get smallScreen() {
    return window.innerWidth < 992;
  }

  get defaultLanguage$(): Observable<string> {
    return this.languages$.pipe(
      map((languages) => languages?.find((lang) => lang.cultureName === this.selectedLangCulture)?.displayName || '')
    );
  }

  get dropdownLanguages$(): Observable<LanguageInfo[]> {
    return this.languages$.pipe(
      map((languages) => languages?.filter((lang) => lang.cultureName !== this.selectedLangCulture) || [])
    );
  }

  get selectedLangCulture(): string {
    return this.sessionState.getLanguage();
  }

  public changeLang(cultureName: string): void {
    this.sessionState.setLanguage(cultureName);
  }
}
