<div>
  <kendo-dialog *ngIf="showDialog" [minWidth]="250" [width]="450">
    <p style="margin: 30px; text-align: center">
      {{ 'NexBase::Title:IdleUserLogoutMessage' | abpLocalization }}
      <span>{{ timerCountDown }}</span>
      {{ 'NexBase::Title:IdleUserLogoutCountdown' | abpLocalization }}
    </p>
    <kendo-dialog-actions>
      <button kendoButton (click)="continueWorking()">
        {{ 'NexBase::Title:IdleUserLogoutContinue' | abpLocalization }}
      </button>
      <button kendoButton (click)="logOut()" primary="true">
        {{ 'NexBase::Title:IdleUserLogout' | abpLocalization }}
      </button>
    </kendo-dialog-actions>
  </kendo-dialog>
</div>
