import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { RequestExtensionOfActivationPeriod } from '../../../models';

@Component({
  selector: 'nexweb-extension-form',
  templateUrl: './extension-form.component.html',
})
export class ExtensionFormComponent implements OnInit {
  @Input()
  extension: RequestExtensionOfActivationPeriod.ActivationExtension;
  @Input() headings: RequestExtensionOfActivationPeriod.Headings;
  @Input() labels: RequestExtensionOfActivationPeriod.Labels;
  @Input() dateTimeFormat: string;

  extensionFormGroup: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.initialise();
  }

  private initialise(): void {
    this.extensionFormGroup = new FormGroup({
      name: new FormControl({ value: this.extension.name, disabled: true }),
      telephoneNumber: new FormControl({
        value: this.extension.telephoneNumber ?? ' ',
        disabled: true,
      }),
      portfolio: new FormControl({
        value: this.extension.portfolio,
        disabled: true,
      }),
      requestedQuantity: new FormControl({
        value: this.extension.requestedQuantity,
        disabled: true,
      }),
      instrument: new FormControl({
        value: this.extension.instrument,
        disabled: true,
      }),
      requestedActivationTime: new FormControl({
        value: this.extension.requestedActivationTime,
        disabled: true,
      }),
      // acknowledgeMessageRead: new FormControl(
      //   {
      //     value: false,
      //   },
      //   [
      //     conditionalValidator(
      //       () => this.mustAcknowledgeMessageRead,
      //       Validators.required
      //     ),
      //   ]
      // ),
    });
  }
}
