import { Injectable, NgZone } from '@angular/core';

import { ConfigStateService } from '@abp/ng.core';
import { Observable, Subject, Subscription, from, fromEvent, interval, merge, of, timer } from 'rxjs';
import {
  bufferTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { UserIdleConfig } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UserIdleService {
  protected activityEvents$: Observable<unknown>;
  protected timerStart$ = new Subject<boolean>();
  protected idleDetected$ = new Subject<boolean>();
  protected timeout$ = new Subject<boolean>();
  protected idle$: Observable<unknown>;
  protected timer$: Observable<number>;
  protected idleMillisec = 1200 * 1000; //Default equals 20 minutes.
  protected idleSensitivityMillisec = 1000; //Default equals 1 Sec.
  protected timeout = 120; //Default equals 2 minutes.
  protected isTimeout: boolean;
  protected isInactivityTimer: boolean;
  protected isIdleDetected: boolean;
  protected idleSubscription: Subscription;

  constructor(private ngZone: NgZone, private configStateService: ConfigStateService) {
    this.setConfig(this._getConfigSettings());
  }

  private _getConfigSettings(): UserIdleConfig {
    return {
      idle: Number(this.configStateService.getSetting('NexBase.UserIdle.Idle')),
      timeout: Number(this.configStateService.getSetting('NexBase.UserIdle.Timeout')),
    };
  }

  /**
   * Start watching for user idle events
   */
  startWatching() {
    if (!this.activityEvents$) {
      this.activityEvents$ = merge(
        fromEvent(window, 'mousemove'),
        fromEvent(window, 'resize'),
        fromEvent(document, 'keydown')
      );
    }

    this.idle$ = from(this.activityEvents$);

    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }

    // If any user events are not active for idle-seconds then start timer.
    this.idleSubscription = this.idle$
      .pipe(
        bufferTime(this.idleSensitivityMillisec), // Starting point for detecting a user's inactivity
        filter((arr) => !arr.length && !this.isIdleDetected && !this.isInactivityTimer),
        tap(() => {
          this.isIdleDetected = true;
          this.idleDetected$.next(true);
        }),
        switchMap(() =>
          this.ngZone.runOutsideAngular(() =>
            interval(1000).pipe(
              takeUntil(
                merge(
                  this.activityEvents$,
                  timer(this.idleMillisec).pipe(
                    tap(() => {
                      this.isInactivityTimer = true;
                      this.timerStart$.next(true);
                    })
                  )
                )
              ),
              finalize(() => {
                this.isIdleDetected = false;
                this.idleDetected$.next(false);
              })
            )
          )
        )
      )
      .subscribe();

    this.setupTimer(this.timeout);
  }

  /**
   * Stop watching for user idle events
   */
  stopWatching() {
    this.stopTimer();

    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }
  }

  /**
   * Stop the timer
   */
  stopTimer() {
    this.isInactivityTimer = false;
    this.timerStart$.next(false);
  }

  /**
   * Reset the timer
   */
  resetTimer() {
    this.stopTimer();
    this.isTimeout = false;
  }

  /**
   * Return an observable for timer's countdown number that emits after idle.
   */
  onTimerStart(): Observable<number> {
    return this.timerStart$.pipe(
      distinctUntilChanged(),
      switchMap((start) => (start ? this.timer$ : of(null)))
    );
  }

  /**
   * Return an observable when timeout is fired.
   */
  onTimeout(): Observable<boolean> {
    return this.timeout$.pipe(
      filter((timeout) => !!timeout),
      tap(() => (this.isTimeout = true)),
      map(() => true)
    );
  }

  /**
   * Get the config values
   */
  getConfigValue(): UserIdleConfig {
    return {
      idle: this.idleMillisec,
      timeout: this.timeout,
    };
  }

  /**
   * Set the config values based on State Settings
   */
  private setConfig(config: UserIdleConfig) {
    if (config.idle) {
      this.idleMillisec = config.idle * 1000;
    }
    if (config.timeout) {
      this.timeout = config.timeout;
    }
  }

  /**
   * Counts down starting with timeout until count == 0.
   * @param timeout number in seconds to cound down from
   */
  protected setupTimer(timeout: number) {
    this.ngZone.runOutsideAngular(() => {
      this.timer$ = interval(1000).pipe(
        take(timeout),
        map(() => (timeout -= 1)),
        tap((count) => {
          if (count === 0) {
            this.timeout$.next(true);
          }
        })
      );
    });
  }
}
