import { Component, OnDestroy, OnInit } from '@angular/core';

import { ReplaceableComponentsService } from '@abp/ng.core';
import { ConfigStateService } from '@abp/ng.core';
import { eThemeLeptonComponents } from '@volo/abp.ng.theme.lepton';
import { Subscription } from 'rxjs';

import { NavItemsComponent } from '@nexweb/shared/abp/ui';
import { SystemMessagingHubService } from '@nexweb/shared/system-messaging';
import { SessionExpiryService } from '@nexweb/shared/ui/user-idle';

@Component({
  selector: 'nexweb-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy, OnInit {
  protected sysMessagingSubscription$: Subscription;

  constructor(
    private sessionExpiryService: SessionExpiryService,
    private configStateService: ConfigStateService,
    private replaceableComponentsService: ReplaceableComponentsService,
    private systemMessagingHubService: SystemMessagingHubService
  ) {
    this.initSessionExpiry();
  }

  /*
   * When user closes browser window
   * Remove all browser window local storage data
   */
  // @HostListener('window:unload', ['$event'])
  // unloadHandler(event) {
  //   if (window.onclose) {
  //     this.sessionExpiryService.clearLoginLocalStorage();
  //   }
  // }

  ngOnInit(): void {
    this.initSystemMessagingHub();
    this.loadNavItems();
  }

  ngOnDestroy(): void {
    this.sessionExpiryService.onDestroy();

    if (this.sysMessagingSubscription$) {
      this.sysMessagingSubscription$.unsubscribe();
    }
  }

  private initSessionExpiry(): void {
    const userIdleActive = JSON.parse(this.configStateService.getSetting('NexBase.UserIdle.Enabled'));
    if (userIdleActive) {
      this.sessionExpiryService.initialize();
    }
  }

  private loadNavItems(): void {
    this.replaceableComponentsService.add({
      component: NavItemsComponent,
      key: eThemeLeptonComponents.NavItems,
    });
  }

  private initSystemMessagingHub(): void {
    this.sysMessagingSubscription$ = this.configStateService.getOne$('currentUser').subscribe((currentUser) => {
      if (currentUser.isAuthenticated) {
        this.systemMessagingHubService.initialize();
      } else {
        this.systemMessagingHubService.stopConnection();
      }
    });
  }
}
