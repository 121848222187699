import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConnectTokenRequestInterceptor implements HttpInterceptor {
  private readonly tokenUrl = ['connect/token'];
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request.url;

    // check if url is the token url
    const isTokenUrl = this.tokenUrl.some((u) => url.includes(u));
    if (isTokenUrl) {
      const modifiedRequest = request.clone({
        body: this.applyProperCasingBody(request.body),
      });

      return next.handle(modifiedRequest);
    }

    return next.handle(request);
  }

  private applyProperCasingBody(body: unknown | HttpParams) {
    // token request body is HttpParams
    // check if body is HttpParams
    if (body instanceof HttpParams) {
      // get all keys
      const keys = body.keys();
      // create new HttpParams
      let newBody = new HttpParams();
      // loop through keys
      for (const key of keys) {
        // check if key is UserName or Password and fix casing
        if (key === 'UserName' || key === 'Password') {
          // append new key with proper casing
          newBody = newBody.append(key.toLowerCase(), body.get(key) as string);
        } else {
          // append key as is
          newBody = newBody.append(key, body.get(key) as string);
        }
      }

      return newBody;
    }

    return body;
  }
}
