<div class="nav-item" *ngIf="currentUser$ | async as user">
  <div *ngIf="user.isAuthenticated">
    <div class="dropdown" ngbDropdown>
      <a
        *ngIf="(dropdownLanguages$ | async)?.length > 0 && defaultLanguage$ | async as defaultLang"
        class="dropdown-toggle btn ng-star-inserted"
        role="button"
        id="dropdownMenuLink"
        ngbDropdownToggle>
        <span
          class="flag-icon flag-icon-squared flag-icon-{{ defaultLang.flagIcon }}"
          [title]="defaultLang.displayName"></span>
        <span class="current-language-name ms-1">
          {{ defaultLang.displayName }}
        </span>
      </a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end ng-star-inserted" *ngIf="(dropdownLanguages$ | async)?.length > 0">
        <a
          *ngFor="let lang of (dropdownLanguages$ | async) || []"
          class="dropdown-item pointer ng-star-inserted"
          (click)="changeLang(lang.cultureName)">
          <span class="flag-icon flag-icon-{{ lang.flagIcon }} flag-icon-squared me-2"></span>
          {{ lang?.displayName }}
        </a>
      </div>
    </div>
  </div>
</div>
