<ul class="navbar-nav toolbar-nav">
  <ng-container *ngFor="let item of getNavItems$() | async; trackBy: trackByFn">
    <ng-container *ngIf="!item.visible ||item.visible()">
      <li class="nav-item d-flex align-items-center" *abpPermission="item.requiredPolicy">
        <ng-container *ngIf="item.component; else htmlTemplate" [ngComponentOutlet]="item.component"></ng-container>

        <ng-template #htmlTemplate>
          <div [innerHTML]="item.html" (click)="item.action ? item.action() : null"></div>
        </ng-template>
      </li>
    </ng-container>
  </ng-container>
</ul>
