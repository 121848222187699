import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { RespondToActivationRequest } from '../../../models';

@Component({
  selector: 'nexweb-decline-form',
  templateUrl: './decline-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeclineFormComponent implements OnInit {
  @Input() notification: RespondToActivationRequest.Notification;
  @Input() labels: RespondToActivationRequest.NotificationLabels;
  @Input() heading: string;
  @Input() dateTimeFormat: string;

  notificationFormGroup: FormGroup;

  constructor() {}

  ngOnInit(): void {
    this.initialise();
  }

  public initialise(): void {
    this.notificationFormGroup = new FormGroup({
      instrument: new FormControl({
        value: this.notification.instrument,
        disabled: true,
      }),
      activatingPortfolio: new FormControl({
        value: this.notification.activatingPortfolio,
        disabled: true,
      }),
      supplyingBspPortfolio: new FormControl({
        value: this.notification.supplyingBspPortfolio,
        disabled: true,
      }),
      activatedQuantity: new FormControl({
        value: this.notification.activatedQuantity,
        disabled: true,
      }),
      timeRequestWasDeclined: new FormControl({
        value: this.notification.timeRequestWasDeclined,
        disabled: true,
      }),
      reasonForDeclining: new FormControl({
        value: this.notification.reasonForDeclining,
        disabled: true,
      }),
      // acknowledgeMessageRead: new FormControl({
      //   value: false,
      // }),
    });
  }
}
