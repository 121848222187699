import { Injectable } from '@angular/core';

import { ConfigStateService } from '@abp/ng.core';
import { BehaviorSubject, Observable, Subscription, interval, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { SammDataAccessFacade } from '@nexweb/shared/data-access/samm';
import { DateTimeHelperService } from '@nexweb/shared/date-time-helper';

@Injectable({
  providedIn: 'root',
})
export class SharedUiComponentService {
  private serverResync: number;
  private _serverTime$ = new BehaviorSubject<Date>(undefined);
  private _serverTimeDisplay$ = new BehaviorSubject<string>(null);
  private _serverTimeInterval$: Subscription;
  private _serverTimeResyncTimer$: Subscription;

  constructor(
    private sammDataAccessFacade: SammDataAccessFacade,
    private configStateService: ConfigStateService,
    private dateTimeHelperService: DateTimeHelperService
  ) {}
  initialise() {
    this.serverResync = Number(this.configStateService.getSetting('NexBase.SyncServerDateTime'));
    this.getServerTime();
  }

  private _getCurrentUser(): boolean {
    return this.configStateService.getDeep('currentUser.isAuthenticated') as boolean;
  }

  getServerTime$(): Observable<Date> {
    return this._serverTime$.asObservable();
  }

  getServerTimeDisplay$(): Observable<string> {
    return this._serverTimeDisplay$.asObservable();
  }

  getServerTime() {
    this._serverTimeResyncTimer$ = timer(0, this.serverResync * 1000)
      .pipe(
        switchMap(() => {
          if (this._getCurrentUser()) {
            return this.sammDataAccessFacade.getServerTime().pipe(
              map((result) => {
                if (result) {
                  this._serverTime$.next(result);

                  //display
                  this._serverTimeDisplay$.next(this.dateTimeHelperService.formatFullDateTime(result));
                }
              })
            );
          } else {
            return of(0);
          }
        })
      )
      .subscribe();

    this._serverTimeInterval$ = interval(1000)
      .pipe(
        map(() => {
          if (this._serverTime$.value !== undefined) {
            this._serverTime$.next(
              this.dateTimeHelperService.add(this._serverTime$.value, {
                seconds: 1,
              })
            );

            this._serverTimeDisplay$.next(
              this.dateTimeHelperService.formatFullDateTime(
                this.dateTimeHelperService.add(this._serverTime$.value, {
                  seconds: 1,
                })
              )
            );
          }
        })
      )
      .subscribe();
  }

  format() {
    return this.dateTimeHelperService.fullDateTimeFormat;
  }

  onDestroy() {
    if (this._serverTimeInterval$) {
      this._serverTimeInterval$.unsubscribe();
    }
    if (this._serverTimeResyncTimer$) {
      this._serverTimeResyncTimer$.unsubscribe();
    }
    if (this._serverTimeDisplay$) {
      this._serverTimeDisplay$.unsubscribe();
    }
    if (this._serverTime$) {
      this._serverTime$.unsubscribe();
    }
  }
}
