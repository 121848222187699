//copy object
export function deepCopy<T>(obj: T) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let copy: any;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || 'object' !== typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());

    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i]);
    }

    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (const attr in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(attr)) {
        if (Array.isArray(obj[attr])) {
          copy[attr] = obj[attr];
        } else {
          copy[attr] = deepCopy(obj[attr]);
        }
      }
    }

    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}

export function inArray<T>(ary: T[], filterAry: unknown[], field: string): T[] {
  const filteredAry = [];

  if (ary !== undefined && filterAry !== undefined) {
    ary.forEach((value: T) => {
      const found = filterAry.find((x) => x === value[field]);

      if (found !== undefined) {
        filteredAry.push(value);
      }
    });
  }

  return filteredAry;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function findFirstProperty(compare, compareWith): any {
  for (const attr in compare) {
    if (compareWith[attr] !== undefined) {
      return attr;
    }
  }

  return null;
}

export const replaceAt = function (value, index, char) {
  const a = value.split('');
  a[index] = char;

  return a.join('');
};

// Santize number from excel
export const sanitizeNumberFromExcel = function (value): number {
  if (isNaN(+value)) {
    //check comma to dot
    if (value.lastIndexOf('.') === -1) {
      if ((value.match(/,/g) || []).length === 1) {
        value = replaceAt(value, value.lastIndexOf(','), '.');
      }
    } //end

    value = value.replace(/\s/g, '');
    value = value.replace(/,/g, '');

    if (isNaN(+value)) {
      return NaN;
    }
  }

  return +value;
};
