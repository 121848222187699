import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AudioService {
  public audio: HTMLAudioElement;

  constructor() {
    this.audio = new Audio();
  }

  /**
   *
   * @param audioSource the source path of the audio file
   * eg '/assets/core/some-file.wav'
   */
  public playSound(audioSource: string): void {
    this.audio.src = audioSource;
    this.audio.load();

    this.audio.volume = 0.3;

    this.audio
      .play()
      .then() // do nothing on success, sound will play
      .catch(); //swallow the error usually means the audio file doesn't exist and app should continue working
  }
}
