import { Injectable } from '@angular/core';

import { Rest, RestService } from '@abp/ng.core';
import { Observable } from 'rxjs';

import { SammApplications, SammModule } from '@nexweb/shared/data-access/samm-enums';

@Injectable({
  providedIn: 'root',
})
export class SammParameterService {
  private sammParameterValueUrl = '/api/Samm/samm-module-parameter/parameter-value';

  constructor(private rest: RestService) {}

  getParameterValue(sammModule: SammModule, sammApplication: SammApplications, paramName: string): Observable<string> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: this.sammParameterValueUrl,
      params: {
        sammModule: sammModule,
        sammApplication: sammApplication,
        paramName: paramName,
        paramDate: new Date().toDateString(),
      },
    };

    return this.rest.request<null, string>(request);
  }
}
