export const isString = function (arg) {
  return Object.prototype.toString.call(arg) === '[object String]';
};

export const isObject = function (arg) {
  return Object.prototype.toString.call(arg) === '[object Object]';
};

export const isDate = function (arg) {
  return Object.prototype.toString.call(arg) === '[object Date]';
};

export const isArray = function (arg) {
  return Object.prototype.toString.call(arg) === '[object Array]';
};

export const isNumber = function (arg) {
  return Object.prototype.toString.call(arg) === '[object Number]';
};

export const isBoolean = function (arg) {
  return Object.prototype.toString.call(arg) === '[object Boolean]';
};

export const isNullOrUndefined = function (value: unknown): value is null | undefined {
  return value === null || isUndefined(value);
};

export const isUndefined = function (value: unknown): value is undefined {
  return value === undefined;
};

export const isUndefinedOrEmptyString = function (value: unknown): boolean {
  return value === undefined || value === '';
};

/**
 *
 * @param value
 * @returns
 */
export const isIsoDateString = function (value) {
  const iso8601ZFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3,7}Z$/;
  const iso8601Format = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  const iso8601FormatNoMs = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

  if (value === null || value === undefined) {
    return false;
  }

  if (isString(value)) {
    return iso8601ZFormat.test(value) || iso8601Format.test(value) || iso8601FormatNoMs.test(value);
  }

  return false;
};

/**
 *
 * @param value
 * @returns
 */
export const isTzIsoDateString = function (value) {
  const iso8601TZFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{1,7}[+]\d{2}:\d{2}$/;
  const iso8601TZFormatNoMilliseconds = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[+]\d{2}:\d{2}$/;

  if (value === null || value === undefined) {
    return false;
  }

  if (isString(value)) {
    return iso8601TZFormat.test(value) || iso8601TZFormatNoMilliseconds.test(value);
  }

  return false;
};

export const isNumeric = function (value) {
  return isString(value) && !isNaN(value) && !isNaN(parseFloat(value));
};
