import { Injectable } from '@angular/core';

import { ConfigStateService, RoutesService, isNullOrUndefined } from '@abp/ng.core';
import { tap } from 'rxjs';

import { createEffect } from '@nexweb/shared/effects';
import { FeatureFlagNames } from '@nexweb/shared/guards/feature-flag';

import { bulkDownloadMenuName } from './constants';

@Injectable({ providedIn: 'root' })
export class BulkDownloadEffects {
  bulkDownloadEffect$ = createEffect(() =>
    this.configStateService.getFeature$(FeatureFlagNames.bulkDownloadEnabled).pipe(
      tap((featureFlag: string) => {
        if (!isNullOrUndefined(featureFlag)) {
          const featureValue = JSON.parse(featureFlag);

          this.routes.patch(bulkDownloadMenuName, { invisible: !featureValue });
        }
      })
    )
  );

  constructor(private configStateService: ConfigStateService, private routes: RoutesService) {}
}
