import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { BidNotificationInfo, BmDataAccessFacade } from '@nexweb/shared/data-access/bm';

import { ActivationExtension, Headings, Labels } from '../../../models/request-extension-of-activation-period.models';
import { RequestExtensionOfActivationPeriodService } from '../../../services/request-extension-of-activation-period.service';

@Component({
  selector: 'nexweb-ext-sup-tso-not',
  templateUrl: './ext-sup-tso-not.component.html',
})
export class ExtSupTsoNotComponent implements OnInit {
  @Input() bidId: number;

  extension$: Observable<ActivationExtension>;
  dateTimeFormat: string;
  labels: Labels;
  headings: Headings;

  constructor(
    private bmFacade: BmDataAccessFacade,
    private requestExtensionOfActivationService: RequestExtensionOfActivationPeriodService
  ) {}

  ngOnInit(): void {
    this.labels = this.requestExtensionOfActivationService.getNotificationLabels();
    this.headings = this.requestExtensionOfActivationService.getNotificationHeadings();
    this.dateTimeFormat = this.requestExtensionOfActivationService.getDateTimeFormat();

    this.initialiseData();
  }

  private initialiseData(): void {
    this.extension$ = this.bmFacade.getBidNotificationInfo(this.bidId).pipe(
      map((bidNotificationInfo: BidNotificationInfo) => {
        const bidNotificationData = {
          name: bidNotificationInfo.requestingTsoName,
          telephoneNumber: bidNotificationInfo.requestingTsoPhoneNumber,
          portfolio: bidNotificationInfo.requestingTsoPortfolioName,
          requestedQuantity: bidNotificationInfo.requestedQuantity,
          instrument: bidNotificationInfo.instrument,
          requestedActivationTime: bidNotificationInfo.requestFullDispatchTime,
        } as ActivationExtension;

        return bidNotificationData;
      })
    );
  }
}
