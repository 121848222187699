import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BidNotificationInfo, BmDataAccessFacade } from '@nexweb/shared/data-access/bm';

import { RespondToActivationRequest } from '../../../models';
import { RespondToActivationRequestService } from '../../../services/respond-to-activation-request.service';

@Component({
  selector: 'nexweb-acceptance',
  templateUrl: './acceptance.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptanceComponent implements OnInit {
  notification$: Observable<RespondToActivationRequest.Notification>;

  @Input() bidId: number;

  labels: RespondToActivationRequest.NotificationLabels;
  dateTimeFormat: string;
  heading: string;

  constructor(
    private respondToActivationRequestService: RespondToActivationRequestService,
    private bmFacade: BmDataAccessFacade
  ) {}

  ngOnInit(): void {
    this.labels = this.respondToActivationRequestService.getAcceptanceLabels();
    this.dateTimeFormat = this.respondToActivationRequestService.getDateTimeFormat();
    this.heading = this.respondToActivationRequestService.getAcceptanceHeading();

    this.initialiseData();
  }

  private initialiseData(): void {
    this.notification$ = this.bmFacade.getBidNotificationInfo(this.bidId).pipe(
      map((bidNotificationInfo: BidNotificationInfo) => {
        const bidNotificationData = {
          instrument: bidNotificationInfo.instrument,
          activatingPortfolio: bidNotificationInfo.requestingTsoPortfolioName,
          supplyingBspPortfolio: bidNotificationInfo.supplyingBspPortfolioName,
          activatedQuantity: bidNotificationInfo.requestedQuantity,
          confirmedActivationTime: bidNotificationInfo.confirmedFullDispatchTime,
          timeRequestWasAccepted: bidNotificationInfo.acceptTime,
        } as RespondToActivationRequest.Notification;

        return bidNotificationData;
      })
    );
  }
}
