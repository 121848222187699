import { Injectable } from '@angular/core';

import { ConfigStateService } from '@abp/ng.core';
import {
  Duration,
  Interval,
  add,
  compareAsc,
  differenceInCalendarDays,
  differenceInDays,
  differenceInHours,
  differenceInSeconds,
  eachDayOfInterval,
  eachMonthOfInterval,
  eachWeekOfInterval,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  intervalToDuration,
  isDate,
  isValid,
  isWithinInterval,
  parse,
  parseISO,
  set,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Subscription } from 'rxjs';
import { first, shareReplay } from 'rxjs/operators';

import {
  deepCopy,
  isArray,
  isBoolean,
  isIsoDateString,
  isNullOrUndefined,
  isNumber,
  isNumeric,
  isObject,
  isString,
  isTzIsoDateString,
} from '@nexweb/util';

export type StartOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export interface SetObject {
  year?: number;
  month?: number;
  date?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}

@Injectable({
  providedIn: 'root',
})
export class DateTimeHelperService {
  private _iso8601Format = `yyyy-MM-dd'T'HH:mm:ss`;
  private _dateStringRegex =
    /(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])\b|(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}\b|(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(19|20)\d{2}\b|(19|20)\d{2}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\b|(January|February|March|April|May|June|July|August|September|October|November|December) (0[1-9]|[12][0-9]|3[01]), (19|20)\d{2}\b|(1[0-2]|0?[1-9]):([0-5][0-9]) (AM|PM)\b|([01]?[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])\b/g;

  constructor(private configStateService: ConfigStateService) {}

  public get serverTimeZoneName(): string {
    let serverTimeZoneName: string;

    this.subscription = this.configStateService
      .getDeep$('timing.timeZone.iana.timeZoneName')
      .pipe(first(), shareReplay(1))
      .subscribe((setting: string) => {
        serverTimeZoneName = setting;
      });

    this.subscription.unsubscribe();

    return serverTimeZoneName;
  }

  public get localTimeZoneName(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  public get hourFormat(): string {
    let hourFormat: string;

    this.subscription = this.configStateService
      .getSetting$('NexBase.HourFormat')
      .pipe(first(), shareReplay(1))
      .subscribe((setting) => {
        hourFormat = setting;
      });

    this.subscription.unsubscribe();

    return hourFormat;
  }

  public get yearFormat(): string {
    let yearFormat: string;

    this.subscription = this.configStateService
      .getSetting$('NexBase.YearFormat')
      .pipe(first(), shareReplay(1))
      .subscribe((setting) => {
        if (setting) yearFormat = setting;
      });

    this.subscription.unsubscribe();

    return yearFormat;
  }

  public get monthFormat(): string {
    let monthFormat: string;

    this.subscription = this.configStateService
      .getSetting$('NexBase.MonthFormat')
      .pipe(first(), shareReplay(1))
      .subscribe((setting) => {
        if (setting) monthFormat = setting;
      });

    this.subscription.unsubscribe();

    return monthFormat;
  }

  public get dateFormat(): string {
    let dateFormat: string;

    this.subscription = this.configStateService
      .getSetting$('NexBase.DateFormat')
      .pipe(first(), shareReplay(1))
      .subscribe((setting) => {
        if (setting) dateFormat = setting;
      });

    this.subscription.unsubscribe();

    return dateFormat;
  }

  public get shortDateTimeFormat(): string {
    let shortDateTimeFormat: string;

    this.subscription = this.configStateService
      .getSetting$('NexBase.DateTimeFormat')
      .pipe(first(), shareReplay(1))
      .subscribe((setting) => {
        if (setting) shortDateTimeFormat = setting;
      });

    this.subscription.unsubscribe();

    return shortDateTimeFormat;
  }

  public get longDateTimeFormat(): string {
    let longDateTimeFormat: string;

    this.subscription = this.configStateService
      .getSetting$('NexBase.LongDateTimeFormat')
      .pipe(first(), shareReplay(1))
      .subscribe((setting) => {
        if (setting) longDateTimeFormat = setting;
      });

    this.subscription.unsubscribe();

    return longDateTimeFormat;
  }

  public get shortTimeFormat(): string {
    let shortTimeFormat: string;

    this.subscription = this.configStateService
      .getSetting$('NexBase.TimeFormat')
      .pipe(first(), shareReplay(1))
      .subscribe((setting) => {
        if (setting) shortTimeFormat = setting;
      });

    this.subscription.unsubscribe();

    return shortTimeFormat;
  }

  public get longTimeFormat(): string {
    let longTimeFormat: string;

    this.subscription = this.configStateService
      .getSetting$('NexBase.LongTimeFormat')
      .pipe(first(), shareReplay(1))
      .subscribe((setting) => {
        if (setting) longTimeFormat = setting;
      });

    this.subscription.unsubscribe();

    return longTimeFormat;
  }

  public get fullDateTimeFormat(): string {
    let fullDateTimeFormat: string;

    this.subscription = this.configStateService
      .getSetting$('NexBase.FullDateFormat')
      .pipe(first(), shareReplay(1))
      .subscribe((setting) => {
        if (setting) fullDateTimeFormat = setting;
      });

    this.subscription.unsubscribe();

    return fullDateTimeFormat;
  }

  public get startOfWeek(): StartOfWeek {
    let startOfWk: StartOfWeek;

    this.subscription = this.configStateService
      .getSetting$('NexBase.StartOfWeek')
      .pipe(first(), shareReplay(1))
      .subscribe((setting) => {
        if (setting) {
          startOfWk = Number(setting) as StartOfWeek;
        }
      });

    this.subscription.unsubscribe();

    return startOfWk;
  }

  public get locale(): string {
    let locale: string;

    this.subscription = this.configStateService
      .getSetting$('NexBase.Locale')
      .pipe(first(), shareReplay(1))
      .subscribe((setting) => {
        if (setting) locale = setting;
      });

    this.subscription.unsubscribe();

    return locale;
  }

  public get overrideCultureDateFormat(): string {
    let overrideCultureDateFormat: string;

    this.subscription = this.configStateService
      .getSetting$('NexBase.OverrideCultureDateFormat')
      .pipe(first(), shareReplay(1))
      .subscribe((setting) => {
        if (setting) overrideCultureDateFormat = setting;
      });

    this.subscription.unsubscribe();

    return overrideCultureDateFormat;
  }

  private subscription: Subscription = new Subscription();

  private static _padNumber(value: number): string {
    return `${value < 10 ? '0' : ''}${value}`;
  }

  /**
   * Return the hour formatted of the date provided
   *
   * **Example**
   *
   * formatHour(new Date(2020,1,1,13,0,0)) => '13'
   *
   * @param value
   */
  public formatHour(value: Date): string {
    return format(value, this.hourFormat);
  }

  /**
   * Return the year formatted string of the date provided
   *
   * **Example**
   *
   * formatYear(new Date(2020,1,1,13,0,0)) => '2020'
   *
   * @param value
   */
  public formatYear(value: Date): string {
    return format(value, this.yearFormat);
  }

  /**
   * Return the month formatted string of the date provided
   *
   * **Example**
   *
   * formatMonth(new Date(2020,1,1,13,0,0)) => '2020/01'
   *
   * @param value
   */
  public formatMonth(value: Date): string {
    return format(value, this.monthFormat);
  }

  /**
   * Return the date formatted string of the date provided
   *
   * **Example**
   *
   * formatDate(new Date(2020,1,1,13,0,0)) => '2020/01/01'
   *
   * @param value
   */
  public formatDate(value: Date): string {
    return format(value, this.dateFormat);
  }

  /**
   * Return the short DateTime formatted string of the date provided
   *
   * **Example**
   *
   * formatDate(new Date(2020,1,1,13,0,0)) => '2020/01/01 13:00'
   *
   * @param value
   */
  public formatShortDateTime(value: Date): string {
    return format(value, this.shortDateTimeFormat);
  }

  /**
   * Return the long DateTime formatted string of the date provided
   *
   * **Example**
   *
   * formatLongDateTime(new Date(2020,1,1,13,0,0)) => '2020/01/01 13:00:00'
   *
   * @param value
   */
  public formatLongDateTime(value: Date): string {
    return format(value, this.longDateTimeFormat);
  }

  /**
   * Return the full DateTime formatted string of the date provided
   *
   * **Example**
   *
   * formatFullDateTime(new Date(2020,1,1,13,0,0)) => 'Wednesday, 2020/01/01 01:00:00 pm'
   *
   * @param value
   */
  public formatFullDateTime(value: Date): string {
    return format(value, this.fullDateTimeFormat);
  }

  /**
   * Return the short Time formatted string of the date provided
   *
   * **Example**
   *
   * formatShortTime(new Date(2020,1,1,13,0,0)) => '13:00'
   *
   * @param value
   */
  public formatShortTime(value: Date): string {
    return format(value, this.shortTimeFormat);
  }

  /**
   * Return the short Time formatted string of the Time string provided
   *
   * **Example**
   *
   * formatShortTimeFromString('13:00') => '13:00'
   *
   */
  public formatShortTimeFromString(value: string): string {
    return format(new Date(`0000-01-01 ${value}`), this.shortTimeFormat);
  }

  /**
   * Return the long Time formatted string of the date provided
   *
   * **Example**
   *
   * formatLongTime(new Date(2020,1,1,13,0,0)) => '13:00:00'
   *
   * @param value
   */
  public formatLongTime(value: Date): string {
    return format(value, this.longTimeFormat);
  }

  /**
   * Return the long Time formatted string of the Time string provided
   *
   * **Example**
   *
   * formatLongTimeFromString('13:00') => '13:00:00'
   *
   * @param value
   */
  public formatLongTimeFromString(value: string): string {
    return format(new Date(`0000-01-01 ${value}`), this.longTimeFormat);
  }

  /**
   * Returns a Date value of the ISO formated DateTime string provided
   *
   * **Example**
   *
   * - toDate(2020-01-01 15:00) => Wed Jan 01 2020 15:00:00 GMT+0200 (South Africa Standard Time)
   * @param value ISO Date format
   */
  public toDate(value: string): Date {
    return parseISO(value);
  }

  /**
   * Returns a Date by converting a UTC DateTime string to Date in the Server's TimeZone
   *
   * **Example**
   *
   * - utcToServerTzDateTime(2020-01-01 13:00) => Wed Jan 01 2020 15:00:00 GMT+0200 (South Africa Standard Time)
   * - utcToServerTzDateTime(2020-01-01T13:00:00.00000Z) => Wed Jan 01 2020 15:00:00 GMT+0200 (South Africa Standard Time)
   *
   * @param utcDateTimeString - UTC DateTime string
   */
  public utcToServerTzDateTime(utcDateTimeString: string): Date {
    const serverDate = utcToZonedTime(utcDateTimeString, this.serverTimeZoneName);

    return serverDate;
  }

  /**
   *
   * @param utcDateTimeString
   * @returns
   */
  public utcToServerDateTime(utcDateTimeString: string): Date {
    //convert string to utcDate first
    const utcDate = zonedTimeToUtc(utcDateTimeString, this.serverTimeZoneName);

    //convert utc date to server date
    const serverDate = utcToZonedTime(utcDate, this.serverTimeZoneName);

    return serverDate;
  }

  /**
   * Get a new Date in the server's time zone
   *
   * **Example**
   *
   * getServerTzDateTime() => Wed Jan 01 2020 15:00:00 GMT+0200 (South Africa Standard Time)
   *
   */
  public getServerTzDateTime(beginning?: boolean): Date {
    const utcDate = zonedTimeToUtc(new Date(), this.localTimeZoneName);

    const serverDate = utcToZonedTime(utcDate, this.serverTimeZoneName);

    if (beginning) {
      serverDate.setHours(0, 0, 0, 0);
    }

    return serverDate;
  }

  /**
   * Get a date and time object back with the supplied values
   *
   * **Example**
   *
   * getDateTime(2020, 0, 0, 13, 0, 0) => Wed Jan 01 2020 13:00:00 GMT+0200 (South Africa Standard Time)
   *
   * @param year
   * @param month zero based month (0 - 11)
   * @param day
   * @param hour
   * @param minute
   * @param second
   */
  public getDateTime(year: number, month: number, day: number, hour: number, minute: number, second: number) {
    return new Date(year, month, day, hour, minute, second);
  }

  /**
   * Returns a Date by converting a DateTime string in the ShortDateTime Format
   *
   * **Example**
   *
   * toShortDateTime('2020/01/01 13:00') => Wed Jan 01 2020 13:00:00 GMT+0200 (South Africa Standard Time)
   *
   * @param value
   */
  public toShortDateTime(value: string): Date {
    return this.parseDate(value, this.shortDateTimeFormat);
  }

  /**
   * Returns a Date by converting a DateTime string in the LongDateTime Format
   *
   * **Example**
   *
   * toLongDateTime('2020/01/01 13:00:00') => Wed Jan 01 2020 13:00:00 GMT+0200 (South Africa Standard Time)
   *
   * @param value
   */
  public toLongDateTime(value: string): Date {
    return this.parseDate(value, this.longDateTimeFormat);
  }

  /**
   * Returns a Date by converting a DateTime string in the FullDateTime Format
   *
   * **Example**
   *
   * toFullDateTime('Wednesday, 2020/01/01 01:00:00 pm') => Wed Jan 01 2020 13:00:00 GMT+0200 (South Africa Standard Time)
   *
   * @param value
   */
  public toFullDateTime(value: string): Date {
    return this.parseDate(value, this.fullDateTimeFormat);
  }

  /**
   * Returns a Date by converting a DateTime string in the ShortTime Format
   *
   * **Example**
   *
   * toLongTime('13:00') => Wed Jan 01 2020 13:00:00 GMT+0200 (South Africa Standard Time)
   *
   * @param value
   */
  public toShortTime(value: string): Date {
    return this.parseDate(value, this.shortTimeFormat);
  }

  /**
   * Returns a Date by converting a DateTime string in the LongTime Format
   *
   * **Example**
   *
   * toLongTime('13:00:00') => Wed Jan 01 2020 13:00:00 GMT+0200 (South Africa Standard Time)
   *
   * @param value
   */
  public toLongTime(value: string): Date {
    return this.parseDate(value, this.longTimeFormat);
  }

  /**
   * Returns a Date by converting a DateTime string in the format provided
   *
   * **Example**
   *
   * parseDate('2020/01/01', 'yyyy/MM/dd') => Wed Jan 01 2020 00:00:00 GMT+0200 (South Africa Standard Time)
   *
   * @param value DateTime String
   * @param _format Format of the DateTime string provided
   */
  public parseDate(value: string, _format: string): Date {
    return parse(value, _format, new Date());
  }

  /**
   * Returns a Time string of the interval provided
   *
   * **Example**
   *
   * intervaleTimeAsString({
   *       days: 0,
   *       hours: 0,
   *       milliseconds: 0,
   *       minutes: 1,
   *       seconds: 0,
   *       ticks: 600000000,
   *       totalDays: 0.0006944444444444445,
   *       totalHours: 0.016666666666666666,
   *       totalMilliseconds: 60000,
   *       totalMinutes: 1,
   *       totalSeconds: 60,
   *     }) => '00:01:00'
   *
   * @param value
   */
  public intervalTimeAsString(value: { hours: number; minutes: number; seconds: number }): string {
    return `${DateTimeHelperService._padNumber(value.hours)}:${DateTimeHelperService._padNumber(
      value.minutes
    )}:${DateTimeHelperService._padNumber(value.seconds)}`;
  }

  /**
   * Returns a formatted string of the Date provided
   *
   * **Example**
   *
   * format(new Date(2020, 1, 1), "yyyy-MM-dd") => '2020-01-01'
   *
   * @param value Date value to format
   * @param _format Format string
   */
  public format(value: Date, _format: string): string {
    return format(value, _format);
  }

  /**
   * Returns a Date after adding the timeString to the Date provided
   *
   * **Example**
   *
   *  addTimeFromString(new Date(2020,1,1), '13:00:00') => Wed Jan 01 2020 13:00:00 GMT+0200 (South Africa Standard Time)
   *
   * @param date
   * @param timeString
   */
  public addTimeFromString(date: Date, timeString: string): Date {
    const timeAsAry = timeString.split(':');

    date.setHours(date.getHours() + +timeAsAry[0]);
    date.setMinutes(date.getMinutes() + +timeAsAry[1]);
    date.setSeconds(date.getSeconds() + +timeAsAry[2]);

    return date;
  }

  /**
   * Returns a Date after subtracting the timeString from the Date provided
   *
   * **Example**
   *
   *  subtractTimeFromString(new Date(2020,1,2), '13:00:00') => Wed Jan 01 2020 11:00:00 GMT+0200 (South Africa Standard Time)
   *
   * @param date
   * @param timeString
   */
  public subtractTimeFromString(date: Date, timeString: string): Date {
    const timeAsAry = timeString.split(':');

    date.setHours(date.getHours() - +timeAsAry[0]);
    date.setMinutes(date.getMinutes() - +timeAsAry[1]);
    date.setSeconds(date.getSeconds() - +timeAsAry[2]);

    return date;
  }

  /**
   * Returns a Date after setting the time with the timeString  provided
   *
   * **Example**
   *
   *  setTimeFromString(new Date(2020,1,1,10,0,0), '13:00:00') => Wed Jan 01 2020 13:00:00 GMT+0200 (South Africa Standard Time)
   *
   * @param date
   * @param timeString
   */
  public setTimeFromString(date: Date, timeString: string): Date {
    const timeAsAry = timeString.split(':');

    date.setHours(+timeAsAry[0]);
    date.setMinutes(+timeAsAry[1]);
    date.setSeconds(+timeAsAry[2]);

    return date;
  }

  /**
   * Returns Time as Date object to enable date functions
   * @param date
   */
  public getTimeAsDate(value: string): Date {
    return new Date(`0000/01/01 ${value}`);
  }

  /**
   * Returns date with time copied from date supplied
   * @param date
   */
  public copyTimeFromDate(date: Date, copyFrom: Date): Date {
    const newDate = <Date>deepCopy(date);
    newDate.setHours(copyFrom.getHours());
    newDate.setMinutes(copyFrom.getMinutes());
    newDate.setSeconds(copyFrom.getSeconds());

    return newDate;
  }

  /**
   * Returns the number milliSeconds of the time in the Date provided
   *
   * **Example**
   *
   *  getTimeAsNumber(new Date(2020,1,1,13,0,0)) => 46800
   *
   * @param date
   */
  public getTimeAsNumber(date: Date) {
    return date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
  }

  /**
   * Returns a Date of the date provided at the start of the period
   *
   * **Example**
   *
   * - startOf(new Date(2020,1,1,13,0,0), 'day') => Wed Jan 01 2020 00:00:00 GMT+0200 (South Africa Standard Time)
   * - startOf(new Date(2020,1,1,13,0,0), 'week') => Sun Dec 29 2019 00:00:00 GMT+0200 (South Africa Standard Time)
   * - startOf(new Date(2020,1,1,13,0,0), 'month') => Wed Jan 01 2020 00:00:00 GMT+0200 (South Africa Standard Time)
   * - startOf(new Date(2020,1,1,13,0,0), 'year') => Wed Jan 01 2020 00:00:00 GMT+0200 (South Africa Standard Time)
   *
   * @param date
   * @param period Select one period from either 'day' or 'week' or 'month' or 'year' or 'quarter'
   */
  public startOf(date: Date, period: 'day' | 'week' | 'month' | 'year' | 'quarter'): Date {
    switch (period) {
      case 'day':
        return startOfDay(date);
      case 'week':
        return startOfWeek(date, { weekStartsOn: this.startOfWeek });
      case 'month':
        return startOfMonth(date);
      case 'year':
        return startOfYear(date);
      case 'quarter':
        return startOfQuarter(date);
      default:
        return startOfDay(date);
    }
  }

  /**
   * Returns a Date of the date provided at the end of the period
   *
   * **Example**
   *
   * - endOf(new Date(2020,1,1,13,0,0), 'day') => Wed Jan 01 2020 23:59:59 GMT+0200 (South Africa Standard Time)
   * - endOf(new Date(2020,1,1,13,0,0), 'week') => Sat Jan 4 2020 23:59:59 GMT+0200 (South Africa Standard Time)
   * - endOf(new Date(2020,1,1,13,0,0), 'month') => Fri Jan 31 2020 23:59:59 GMT+0200 (South Africa Standard Time)
   * - endOf(new Date(2020,1,1,13,0,0), 'year') => Thu Dec 31 2020 23:59:59 GMT+0200 (South Africa Standard Time)
   *
   * @param date
   * @param period Select one period from either 'day' or 'week' or 'month' or 'year' or 'quarter'
   */
  public endOf(date: Date, period: 'day' | 'week' | 'month' | 'year' | 'quarter'): Date {
    switch (period) {
      case 'day':
        return endOfDay(date);
      case 'week':
        return endOfWeek(date, { weekStartsOn: this.startOfWeek });
      case 'month':
        return endOfMonth(date);
      case 'year':
        return endOfYear(date);
      case 'quarter':
        return endOfQuarter(date);
      default:
        return endOfDay(date);
    }
  }

  /**
   * Returns a Date by adding the periods provided in the duration
   *
   * **Example**
   *
   * - add(new Date(2020,1,1,13,0,0), {days: 1}) => Thu Jan 02 2020 13:00:00 GMT+0200 (South Africa Standard Time)
   * - add(new Date(2020,1,1,13,0,0), {days: 1, months: 1}) => Sun Feb 02 2020 13:00:00 GMT+0200 (South Africa Standard Time)
   * - add(new Date(2020,1,1,13,0,0), {days: 1, months: 1, hours: 12}) => Mon Feb 03 2020 01:00:00 GMT+0200 (South Africa Standard Time)
   *
   * @param date
   * @param duration years/months/weeks/days/hours/minutes/seconds
   */
  public add(date: Date, duration: Duration): Date {
    return add(date, duration);
  }

  /**
   * Returns a boolean value which indicates whether the date is within
   * the provided start and end date of the interval
   * @param date
   * @param interval
   * @returns
   */
  public isWithinInterval(date: Date, interval: Interval): boolean {
    return isWithinInterval(date, interval);
  }

  /**
   * Return an array of Dates from startDate to endDate
   * @param startDate
   * @param endDate
   * @returns
   */
  public getDateRange(startDate: Date, endDate: Date): Date[] {
    const days = differenceInDays(this.startOf(endDate, 'day'), this.startOf(startDate, 'day'));

    return [...Array(days + 1).keys()].map((i) => this.add(this.startOf(startDate, 'day'), { days: i }));
  }

  /**
   * Returns the difference in days
   * @param endDate
   * @param startDate
   * @returns
   */
  public differenceInDays(endDate: Date, startDate: Date): number {
    return differenceInDays(this.startOf(endDate, 'day'), this.startOf(startDate, 'day'));
  }

  /**
   * Compare the two dates and return 1 if the first date is after the second,
   * -1 if the first date is before the second or 0 if dates are equal.
   * @param dateLeft
   * @param dateRight
   * @returns
   */
  public compare(dateLeft: number | Date, dateRight: number | Date) {
    return compareAsc(dateLeft, dateRight);
  }

  /**
   * Loops through an object and checks whether
   * a string value is an iso date and converts
   * it to a date using the servers time zone
   * @param obj
   * @returns
   */
  public convertJsonIsoStringsToDate(obj: unknown) {
    if (obj === null || obj === undefined) {
      return obj;
    }

    if (isString(obj) && isTzIsoDateString(obj)) {
      obj = this.utcToServerTzDateTime(obj as string);

      return obj;
    } else if (isString(obj) && isIsoDateString(obj)) {
      obj = this.utcToServerDateTime(obj as string);

      return obj;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      for (const prop in obj as any) {
        if (isTzIsoDateString(obj[prop])) {
          obj[prop] = this.utcToServerTzDateTime(obj[prop]);
        } else if (isIsoDateString(obj[prop])) {
          obj[prop] = this.utcToServerDateTime(obj[prop]);
        } else if (isObject(obj[prop]) || isArray(obj[prop])) {
          obj[prop] = this.convertJsonIsoStringsToDate(obj[prop]);
        }
      }
    }

    return obj;
  }

  /**
   *
   * @param obj
   */
  public convertDateStringsToJsonIsoStrings(obj: unknown) {
    if (obj === null || obj === undefined) {
      return obj;
    }

    if (isDate(obj)) {
      obj = this.format(obj as Date, this._iso8601Format);
    }

    if (
      !isNumber(obj) &&
      !isBoolean(obj) &&
      !isArray(obj) &&
      !isObject(obj) &&
      this.isStringValidDate(obj.toString())
    ) {
      obj = this.formatLongDateTime(new Date(obj.toString()));

      return obj;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      for (const prop in obj as any) {
        if (isDate(obj[prop])) {
          obj[prop] = this.format(obj[prop] as Date, this._iso8601Format);
        } else if (isObject(obj[prop]) || isArray(obj[prop])) {
          obj[prop] = this.convertDateStringsToJsonIsoStrings(obj[prop]);
        } else if (!isNumber(obj[prop]) && !isBoolean(obj[prop]) && this.isStringValidDate(obj[prop])) {
          obj[prop] = this.formatLongDateTime(new Date(obj[prop]));
        }
      }
    }

    return obj;
  }

  /**
   * Returns the difference in seconds
   * @param endDate
   * @param startDate
   * @returns
   */
  public differenceInSeconds(endDate: Date, startDate: Date): number {
    return differenceInSeconds(endDate, startDate);
  }

  /**
   * Set date values to a given date.
   * Sets time values to date from object values.
   * A value is not set if it is undefined or null or doesn't exist in values.
   * @param date
   * @param values
   * @returns
   */
  public set(date: Date, values: SetObject): Date {
    return set(date, values as unknown);
  }

  /**
   * Return the array of months within the specified time interval.
   * @param interval
   * @returns
   */
  public eachMonthOfInterval(interval: Interval): Date[] {
    return eachMonthOfInterval(interval);
  }

  /**
   * Return the array of dates within the specified time interval.
   * @param interval
   * @returns
   */
  public eachDayOfInterval(interval: Interval): Date[] {
    return eachDayOfInterval(interval);
  }

  /**
   * Return the array of weeks within the specified time interval.
   * @param interval
   * @param options
   * @returns
   */
  public eachWeekOfInterval(
    interval: Interval,
    options?: { locale?: Locale; weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 }
  ): Date[] {
    return eachWeekOfInterval(interval, options);
  }

  /**
   * https://date-fns.org/v2.27.0/docs/intervalToDuration
   * @param interval
   * @returns
   */
  public intervalToDuration(interval: Interval): Duration {
    return intervalToDuration(interval);
  }

  /**
   *
   * @param date
   */
  public toUtcMilliseconds(date: Date): number {
    const time = date.getTime();
    let timezoneOffset = date.getTimezoneOffset();
    timezoneOffset = timezoneOffset * 60000;

    const utcMilliseconds = time - timezoneOffset;

    return utcMilliseconds;
  }

  /**
   * Returns date object from utc milliseconds
   * @param utcMilliseconds
   */
  public fromUtcMilliseconds(utcMilliseconds: number): Date {
    // converts date to local offset by default
    const localDate = new Date(utcMilliseconds);

    // get timezone so we can return correct utc date
    const timezone = localDate.getTimezoneOffset();

    // get utc date without local timezone offset
    const utcDate = new Date(localDate.setMinutes(localDate.getMinutes() + timezone));

    return utcDate;
  }

  /**
   * Returns number of hours between two dates
   * @param startDate
   * @param endDate
   */
  public differenceInHours(startDate: Date, endDate: Date) {
    return differenceInHours(endDate, startDate);
  }

  /**
   * Returns number of days between two dates
   * @param startDate
   * @param endDate
   */
  public differenceInCalendarDays(startDate: Date, endDate: Date): number {
    return differenceInCalendarDays(endDate, startDate);
  }

  public isStringValidDate(dateString: string) {
    if (isNullOrUndefined(dateString)) return false;
    if (isNumeric(dateString)) return false;

    const matches = dateString.match(this._dateStringRegex);

    if (!matches || matches.length === 0) return false;

    return isValid(new Date(dateString));
  }
}
