import { Injectable } from '@angular/core';

import { ConfigStateService, LocalizationService } from '@abp/ng.core';

import { IssueDeactivationInstruction } from '../models';

@Injectable({
  providedIn: 'root',
})
export class IssueDeactivationInstructionService {
  constructor(private localizationService: LocalizationService, private configStateService: ConfigStateService) {}

  public getNotificationLabels(): IssueDeactivationInstruction.Labels {
    return {
      name: this.localizationService.instant({
        key: 'Bm::IssueDeactivationInstructionLabelTsoName',
        defaultValue: 'TSO Name',
      }),
      telephoneNumber: this.localizationService.instant({
        key: 'Bm::IssueDeactivationInstructionLabelTsoTelNum',
        defaultValue: 'TSO Telephone no',
      }),
      portfolio: this.localizationService.instant({
        key: 'Bm::IssueDeactivationInstructionLabelTsoPortfolio',
        defaultValue: 'TSO Portfolio',
      }),
      quantity: this.localizationService.instant({
        key: 'Bm::IssueDeactivationInstructionLabelOrderQuantity',
        defaultValue: 'Order Quantity (MW)',
      }),
      confirmedFullDispatchTime: this.localizationService.instant({
        key: 'Bm::LabelConfirmedFullDispatchTime',
        defaultValue: 'Confirmed Full Dispatch Time',
      }),
      fullyDeactivatedTime: this.localizationService.instant({
        key: 'Bm::IssueDeactivationInstructionLabelFullyDeactivatedTime',
        defaultValue: 'Fully Deactivated Time',
      }),
    } as IssueDeactivationInstruction.Labels;
  }

  public getNotificationHeadings(): IssueDeactivationInstruction.Headings {
    return {
      deactivationInstruction: this.localizationService.instant({
        key: 'Bm::IssueDeactivationInstructionHeadingNotification',
        defaultValue: 'A Deactivation Instruction has been issued by the following TSO',
      }),
      details: this.localizationService.instant({
        key: 'Bm::IssueDeactivationInstructionHeadingDetails',
        defaultValue: 'Details of Deactivation Instruction',
      }),
    } as IssueDeactivationInstruction.Headings;
  }

  public getConfirmationLabels(): IssueDeactivationInstruction.Labels {
    return {
      name: this.localizationService.instant({
        key: 'Bm::LabelSupplyingTsoName',
        defaultValue: 'Supplying TSO Name',
      }),
      telephoneNumber: this.localizationService.instant({
        key: 'Bm::LabelSupplyingTsoTelNum',
        defaultValue: 'Supplying TSO Telephone no',
      }),
      portfolio: this.localizationService.instant({
        key: 'Bm::IssueDeactivationInstructionLabelBspPortfolio',
        defaultValue: 'BSP Portfolio',
      }),
      quantity: this.localizationService.instant({
        key: 'Bm::IssueDeactivationInstructionLabelOrderQuantity',
        defaultValue: 'Order Quantity (MW)',
      }),
      confirmedFullDispatchTime: this.localizationService.instant({
        key: 'Bm::LabelConfirmedFullDispatchTime',
        defaultValue: 'Confirmed Full Dispatch Time',
      }),
      fullyDeactivatedTime: this.localizationService.instant({
        key: 'Bm::IssueDeactivationInstructionLabelFullyDeactivatedTime',
        defaultValue: 'Fully Deactivated Time',
      }),
    } as IssueDeactivationInstruction.Labels;
  }

  public getConfirmationHeadings(): IssueDeactivationInstruction.Headings {
    return {
      deactivationInstruction: this.localizationService.instant({
        key: 'Bm::IssueDeactivationInstructionHeadingConfirmation',
        defaultValue: 'A Deactivation Instruction has been issued to the following TSO',
      }),
      details: this.localizationService.instant({
        key: 'Bm::IssueDeactivationInstructionHeadingDetails',
        defaultValue: 'Details of Deactivation Instruction',
      }),
    } as IssueDeactivationInstruction.Headings;
  }
  public getDateTimeFormat(): string {
    return this.configStateService.getSetting('NexBase.DateTimeFormat');
  }
}
