import { Component, OnInit } from '@angular/core';

import { ConfigStateService } from '@abp/ng.core';

import { isNullOrUndefined } from '@nexweb/util';

@Component({
  selector: 'nexweb-environment-indicator',
  templateUrl: './environment-indicator.component.html',
  styleUrls: ['./environment-indicator.component.scss'],
})
export class EnvironmentIndicatorComponent implements OnInit {
  public environmentIndicatorValue: string;
  public displayEnvironmentIndicator: boolean;

  constructor(private configStateService: ConfigStateService) {}

  ngOnInit(): void {
    const displayEnvironment = this.configStateService.getSetting('NexBase.DisplayEnvironmentIndicator');
    const environmentValue = this.configStateService.getSetting('NexBase.EnvironmentIndicatorValue');

    if (!isNullOrUndefined(displayEnvironment)) {
      this.displayEnvironmentIndicator = JSON.parse(displayEnvironment);
    }

    if (!isNullOrUndefined(environmentValue)) {
      this.environmentIndicatorValue = environmentValue;
    }
  }
}
