import { Injectable } from '@angular/core';

import { ConfirmationService } from '@abp/ng.theme.shared';

@Injectable({
  providedIn: 'root',
})
export class SystemNotificationService {
  constructor(private confirmationService: ConfirmationService) {}

  public show(messageText: string) {
    this.confirmationService.info(messageText, 'Mdd::Title:Notification', {
      hideCancelBtn: true,
      yesText: 'AbpAccount::Close',
    });
  }
}
