export enum NotificationTypeEnum {
  systemNotification = 1,
  requestingTsoConfirmation = 2,
  supplyingTsoNotification = 3,
  supplyingBspNotification = 4,
  acceptanceOfActivationRequest = 5,
  declinationOfActivationRequest = 6,
  deactivationInstructingTsoConfirmation = 7,
  deactivationSupplyingTsoNotification = 8,
  requestingTsoConfirmationOfExtension = 9,
  supplyingTsoNotificationOfExtension = 10,
  supplyingBspNotificationOfExtension = 11,
  deactivationSupplyingBspNotification = 12,
  errorNotification = 99,
}
