<form [formGroup]="notificationFormGroup">
  <div>
    <span>
      <h5>{{ heading }}</h5>
    </span>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.instrument }}">
        <input kendoTextBox formControlName="instrument" class="k-input-full" [value]="notification.instrument" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.activatingPortfolio }}">
        <input
          kendoTextBox
          formControlName="activatingPortfolio"
          class="k-input-full"
          [value]="notification.activatingPortfolio" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.supplyingBspPortfolio }}">
        <input
          kendoTextBox
          formControlName="supplyingBspPortfolio"
          class="k-input-full"
          [value]="notification.supplyingBspPortfolio" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.activatedQuantity }}">
        <input
          kendoTextBox
          formControlName="activatedQuantity"
          class="k-input-full"
          [value]="notification.activatedQuantity" />
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.confirmedActivationTime }}">
        <kendo-datetimepicker
          formControlName="confirmedActivationTime"
          [format]="dateTimeFormat"
          class="k-input-full"
          [value]="notification.confirmedActivationTime"></kendo-datetimepicker>
      </kendo-floatinglabel>
    </div>
  </div>
  <div class="form-group input-group">
    <div class="col-md-12">
      <kendo-floatinglabel class="k-input-full" text="{{ labels.timeRequestWasAccepted }}">
        <kendo-datetimepicker
          formControlName="timeRequestWasAccepted"
          [format]="dateTimeFormat"
          class="k-input-full"
          [value]="notification.timeRequestWasAccepted"></kendo-datetimepicker>
      </kendo-floatinglabel>
    </div>
  </div>
  <!--<div class="space10"></div>
    <div class="form-group input-group text-right">
    <div class="col-md-12">
      <nexweb-acknowledge-message-read
        formControlName="acknowledgeMessageRead"
        (change)="onCheckboxChange($event)"
        [acknowledgementMessage]="acknowledgementMessage"
      ></nexweb-acknowledge-message-read>
    </div>
  </div> -->
</form>
