/* eslint-disable @nx/enforce-module-boundaries */
import { Routes } from '@angular/router';

export const mtpRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('@nexweb/mtp/feature-home').then((m) => m.MtpFeatureHomeModule),
  },
  {
    path: 'account',
    loadChildren: () => import('@volo/abp.ng.account/public').then((m) => m.AccountPublicModule.forLazy()),
  },
  {
    path: 'identity',
    loadChildren: () => import('@volo/abp.ng.identity').then((m) => m.IdentityModule.forLazy()),
  },
  {
    path: 'language-management',
    loadChildren: () => import('@volo/abp.ng.language-management').then((m) => m.LanguageManagementModule.forLazy()),
  },
  {
    path: 'saas',
    loadChildren: () => import('@volo/abp.ng.saas').then((m) => m.SaasModule.forLazy()),
  },
  {
    path: 'audit-logs',
    loadChildren: () => import('@volo/abp.ng.audit-logging').then((m) => m.AuditLoggingModule.forLazy()),
  },
  {
    path: 'openiddict',
    loadChildren: () => import('@volo/abp.ng.openiddictpro').then((m) => m.OpeniddictproModule.forLazy()),
  },
  {
    path: 'text-template-management',
    loadChildren: () =>
      import('@volo/abp.ng.text-template-management').then((m) => m.TextTemplateManagementModule.forLazy()),
  },
  {
    path: 'setting-management',
    loadChildren: () => import('@abp/ng.setting-management').then((m) => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'chat',
    loadChildren: () => import('@volo/abp.ng.chat').then((m) => m.ChatModule.forLazy()),
  },
  {
    path: 'nav-file-management',
    loadChildren: () => import('@volo/abp.ng.file-management').then((m) => m.FileManagementModule.forLazy()),
  },
  {
    path: 'mdd',
    loadChildren: () => import('@nexweb/mdd/feature-mdd').then((m) => m.MddFeatureMddModule),
  },
  {
    path: 'smas',
    loadChildren: () => import('@nexweb/smas/feature-smas').then((m) => m.SmasFeatureSmasModule),
  },
  {
    path: 'bm',
    loadChildren: () => import('@nexweb/bm/feature-bm').then((m) => m.BmFeatureBmModule),
  },
  {
    path: 'tsam',
    loadChildren: () => import('@nexweb/tsam/feature-tsam').then((m) => m.TsamFeatureTsamModule),
  },
  {
    path: 'aum',
    loadChildren: () => import('@nexweb/aum/feature-aum').then((m) => m.AumFeatureAumModule),
  },
  {
    path: 'samm',
    loadChildren: () => import('@nexweb/samm/feature-samm').then((m) => m.SammFeatureSammModule),
  },
  {
    path: 'cal',
    loadChildren: () => import('@nexweb/cal/feature-cal').then((m) => m.CalFeatureCalModule),
  },
  {
    path: 'emmd',
    loadChildren: () => import('@nexweb/emmd/feature-emmd').then((m) => m.EmmdFeatureEmmdModule),
  },
  {
    path: 'tou',
    loadChildren: () => import('@nexweb/tou/feature-tou').then((m) => m.TouFeatureTouModule),
  },
  {
    path: 'amt',
    loadChildren: () => import('@nexweb/amt/feature-amt').then((m) => m.AmtFeatureAmtModule),
  },
  {
    path: 'cmt',
    loadChildren: () => import('@nexweb/cmt/feature-cmt').then((m) => m.CmtFeatureCmtModule),
  },
  {
    path: 'setrep',
    loadChildren: () => import('@nexweb/setrep/feature-setrep').then((m) => m.SetrepFeatureSetrepModule),
  },
  {
    path: 'scheduler',
    loadChildren: () => import('@nexweb/scheduler/feature-scheduler').then((m) => m.SchedulerFeatureSchedulerModule),
  },
  {
    path: 'bulk-download',
    loadChildren: () =>
      import('@nexweb/bulk-download/feature-bulk-download').then((m) => m.BulkDownloadFeatureBulkDownloadModule),
  },
];
