import { Injectable } from '@angular/core';

import { Rest, RestService } from '@abp/ng.core';
import { Observable } from 'rxjs';

import { BidNotificationInfo } from '../models';

@Injectable({
  providedIn: 'root',
})
export class BidNotificationService {
  private baseApiUrl = '/api/Bm/bm-bid-activation';

  constructor(private rest: RestService) {}

  /**
   *
   * @param bidId
   */
  getBidNotificationInfo(bidId: number): Observable<BidNotificationInfo> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: `${this.baseApiUrl}/bid-notification-info/${bidId}`,
      params: { bidId: bidId },
    };

    return this.rest.request<null, BidNotificationInfo>(request);
  }
}
