import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';

import { CoreModule as AbpCoreModule } from '@abp/ng.core';
import { AbpOAuthModule } from '@abp/ng.oauth';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogModule } from '@progress/kendo-angular-dialog';

import { USER_IDLE_COMPONENTS } from './components';

@NgModule({
  imports: [DialogModule, ButtonsModule, CommonModule, AbpCoreModule, AbpOAuthModule],
  exports: [...USER_IDLE_COMPONENTS],
  declarations: [...USER_IDLE_COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedUserIdleModule {
  static forRoot(): ModuleWithProviders<SharedUserIdleModule> {
    return {
      ngModule: SharedUserIdleModule,
    };
  }
}
