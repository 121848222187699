import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import defaultConfig from '../assets/kgw-config.json';
import { KgwConfig } from '../models/kgw-config.model';

@Injectable({
  providedIn: 'root',
})
export class KgwConfigService {
  constructor(private http: HttpClient) {}

  private _config!: KgwConfig;

  public loadKgwConfig$(): Observable<KgwConfig> {
    return this.http.get('/assets/kgw/kgw-config.json').pipe(
      map((config: KgwConfig) => (this._config = { ...defaultConfig, ...config } as unknown as KgwConfig)),
      catchError((err, caught) => {
        this._config = defaultConfig as unknown as KgwConfig;

        return caught;
      })
    );
  }

  //return config
  get config(): KgwConfig {
    if (!this._config) {
      throw Error('Config file not loaded!');
    }

    return this._config;
  }
}
