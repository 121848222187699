import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ABP, Rest, RestService } from '@abp/ng.core';
import { Observable } from 'rxjs';

import { AbRoleManagement, ActiveUserRoles, CurrentUserActiveRole } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AumService {
  private roleApiUrl = '/api/identity/roles/all?MaxResultCount=1000';
  private currentUserActiveRoleUrl = '/api/Aum/aum-role-active/current-user-active-role';
  private activeUserRolesUrl = '/api/Aum/aum-role-active/active-user-roles';

  constructor(private rest: RestService) {}

  public getRoles(params = {} as ABP.PageQueryParams): Observable<AbRoleManagement.Response> {
    const request: Rest.Request<null> = {
      method: 'GET',
      url: this.roleApiUrl,
      params,
    };

    return this.rest.request<null, AbRoleManagement.Response>(request);
  }

  public setCurrentUserActiveRole(body: CurrentUserActiveRole): Observable<null> {
    const request: Rest.Request<string> = {
      method: 'PUT',
      url: this.currentUserActiveRoleUrl,
      body: JSON.stringify(body),
      headers: new HttpHeaders().set('Content-Type', 'application/json-patch+json'),
    };

    return this.rest.request<string, null>(request);
  }

  public getActiveUserRoles(userId: string): Observable<ActiveUserRoles[]> {
    const url = `${this.activeUserRolesUrl}/${userId}`;
    const request: Rest.Request<null> = {
      method: 'GET',
      url,
    };

    return this.rest.request<null, ActiveUserRoles[]>(request);
  }
}
