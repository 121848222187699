import { Injectable } from '@angular/core';

import { LocalizationService } from '@abp/ng.core';

import { DateTimeHelperService } from '@nexweb/shared/date-time-helper';

import { RespondToActivationRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class RespondToActivationRequestService {
  constructor(private localizationService: LocalizationService, private dateHelperService: DateTimeHelperService) {}

  public getTsoResponseLabels(): RespondToActivationRequest.ResponseLabels {
    return {
      currentInstrument: this.localizationService.instant({
        key: 'Bm::LabelCurrentInstrument',
        defaultValue: 'Current Instrument',
      }),
      subsequentInstrument: this.localizationService.instant({
        key: 'Bm::LabelSubsequentInstrument',
        defaultValue: 'Subsequent Instrument',
      }),
      requestingTsoPortfolio: this.localizationService.instant({
        key: 'Bm::LabelRequestingTso:Portfolio',
        defaultValue: 'Requesting TSO Portfolio',
      }),
      supplyingBspPortfolio: this.localizationService.instant({
        key: 'Bm::LabelSupplyingBsp:Portfolio',
        defaultValue: 'Supplying BSP Portfolio',
      }),
      requestedQuantity: this.localizationService.instant({
        key: 'Bm::LabelRequestedQuantity',
        defaultValue: 'Requested Quantity (MW)',
      }),
      defaultFullDispatchTime: this.localizationService.instant({
        key: 'Bm::LabelDefaultFullDispatchTime',
        defaultValue: 'Default Full Dispatch Time',
      }),
      earlyActivationRequested: this.localizationService.instant({
        key: 'Bm::LabelEarlyActivationRequested',
        defaultValue: 'Early Activation Requested',
      }),
      requestedFullDispatchTime: this.localizationService.instant({
        key: 'Bm::LabelRequestedFullDispatchTime',
        defaultValue: 'Requested Full Dispatch Time',
      }),
      confirmedFullDispatchTime: this.localizationService.instant({
        key: 'Bm::LabelConfirmedFullDispatchTime',
        defaultValue: 'Confirmed Full Dispatch Time',
      }),
      activationRequestAccepted: this.localizationService.instant({
        key: 'Bm::LabelActivationRequestAccepted',
        defaultValue: 'Activation Request Accepted',
      }),
      reasonForDeclining: this.localizationService.instant({
        key: 'Bm::LabelReasonForDecliningActivationRequest',
        defaultValue: 'Reason for Declining the Activation Request',
      }),
    } as RespondToActivationRequest.ResponseLabels;
  }

  public getDateTimeFormat(): string {
    return this.dateHelperService.shortDateTimeFormat;
  }

  public getAcceptanceLabels(): RespondToActivationRequest.NotificationLabels {
    return {
      instrument: this.localizationService.instant({
        key: 'Bm::LabelInstrument',
        defaultValue: 'Instrument',
      }),
      activatingPortfolio: this.localizationService.instant({
        key: 'Bm::RespondToActivationRequestLabelActivatingPortfolio',
        defaultValue: 'Activating Portfolio',
      }),
      supplyingBspPortfolio: this.localizationService.instant({
        key: 'Bm::LabelSupplyingBspPortfolio',
        defaultValue: 'Supplying BSP Portfolio',
      }),
      activatedQuantity: this.localizationService.instant({
        key: 'Bm::RespondToActivationRequestLabelActivatedQuantity',
        defaultValue: 'Activated Quantity (MW)',
      }),
      confirmedActivationTime: this.localizationService.instant({
        key: 'Bm::RespondToActivationRequestLabelConfirmedActivationTime',
        defaultValue: 'Confirmed Activation Time',
      }),
      timeRequestWasAccepted: this.localizationService.instant({
        key: 'Bm::RespondToActivationRequestLabelTimeRequestAccepted',
        defaultValue: 'Time request was accepted',
      }),
      timeRequestWasDeclined: this.localizationService.instant({
        key: 'Bm::RespondToActivationRequestLabelTimeRequestDeclined',
        defaultValue: 'Time request was declined',
      }),
      reasonForDeclining: this.localizationService.instant({
        key: 'Bm::RespondToActivationRequestLabelReasonForDeclining',
        defaultValue: 'Reason for Declining the Activation Request',
      }),
    } as RespondToActivationRequest.NotificationLabels;
  }

  public getAcceptanceHeading(): string {
    return this.localizationService.instant({
      key: 'Bm::RespondToActivationRequestHeadingAccepted',
      defaultValue: 'The following Activation Request has been accepted',
    });
  }

  public getDeclinationLabels(): RespondToActivationRequest.NotificationLabels {
    return {
      instrument: this.localizationService.instant({
        key: 'Bm::LabelInstrument',
        defaultValue: 'Instrument',
      }),
      activatingPortfolio: this.localizationService.instant({
        key: 'Bm::RespondToActivationRequestLabelActivatingPortfolio',
        defaultValue: 'Activating Portfolio',
      }),
      supplyingBspPortfolio: this.localizationService.instant({
        key: 'Bm::LabelSupplyingBspPortfolio',
        defaultValue: 'Supplying BSP Portfolio',
      }),
      activatedQuantity: this.localizationService.instant({
        key: 'Bm::RespondToActivationRequestLabelActivatedQuantity',
        defaultValue: 'Activated Quantity (MW)',
      }),
      confirmedActivationTime: this.localizationService.instant({
        key: 'Bm::RespondToActivationRequestLabelConfirmedActivationTime',
        defaultValue: 'Confirmed Activation Time',
      }),
      timeRequestWasAccepted: this.localizationService.instant({
        key: 'Bm::RespondToActivationRequestLabelTimeRequestAccepted',
        defaultValue: 'Time request was accepted',
      }),
      timeRequestWasDeclined: this.localizationService.instant({
        key: 'Bm::RespondToActivationRequestLabelTimeRequestDeclined',
        defaultValue: 'Time request was declined',
      }),
      reasonForDeclining: this.localizationService.instant({
        key: 'Bm::RespondToActivationRequestLabelReasonForDeclining',
        defaultValue: 'Reason for Declining the Activation Request',
      }),
    } as RespondToActivationRequest.NotificationLabels;
  }

  public getDeclinationHeading(): string {
    return this.localizationService.instant({
      key: 'Bm::RespondToActivationRequestHeadingDeclined',
      defaultValue: 'The following Activation Request has been declined',
    });
  }
}
