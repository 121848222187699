import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { LocalizationService } from '@abp/ng.core';
import { Subscription } from 'rxjs/internal/Subscription';

import { conditionalValidator } from '@nexweb/util';

import { RequestExtensionOfActivationPeriod } from '../../../models';

@Component({
  selector: 'nexweb-ext-tso-response-form',
  templateUrl: './ext-tso-response-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtTsoResponseFormComponent implements OnInit, OnDestroy {
  @Input()
  response: RequestExtensionOfActivationPeriod.ExtensionResponse;
  @Input() labels: RequestExtensionOfActivationPeriod.ExtensionResponseLabels;
  @Input() dateTimeFormat: string;
  @Output() formValuesChanged: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() formValidationChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  responseFormGroup: FormGroup;
  responseFormGroupValueChange$: Subscription;

  get requiredFieldError() {
    return this.localizationService.instant({
      key: 'AbpValidation::ThisFieldIsRequired',
      defaultValue: 'This field is required.',
    });
  }
  get minLengthError() {
    return this.localizationService.instant({
      key: 'AbpIdentity::MinFieldLength',
      defaultValue: 'A minimum of 50 characters is required',
    });
  }
  get maxLengthError() {
    return this.localizationService.instant({
      key: 'AbpIdentity::MaxFieldLength',
      defaultValue: 'A maximum of 200 characters is allowed',
    });
  }

  get reasonForDeclining() {
    return this.responseFormGroup.get('reasonForDeclining');
  }
  get activationRequestAccepted() {
    return this.responseFormGroup.get('activationRequestAccepted');
  }

  constructor(private localizationService: LocalizationService) {}

  ngOnInit(): void {
    this.initializeForm();
    this.initializeFormControlSubscription();

    //emit default values
    this.formValuesChanged.emit({
      confirmedFullDispatchTime: this.response.confirmedFullDispatchTime,
      activationRequestAccepted: true,
      reasonForDeclining: ' ',
      concurrencyStamp: this.response.concurrencyStamp,
    });
  }

  ngOnDestroy(): void {
    if (this.responseFormGroupValueChange$) {
      this.responseFormGroupValueChange$.unsubscribe();
    }
  }

  private initializeForm(): void {
    this.responseFormGroup = new FormGroup({
      instrument: new FormControl({
        value: this.response.instrument,
        disabled: true,
      }),
      requestingTsoPortfolio: new FormControl({
        value: this.response.requestingTsoPortfolio,
        disabled: true,
      }),
      supplyingBspPortfolio: new FormControl({
        value: this.response.supplyingBspPortfolio,
        disabled: true,
      }),
      requestedQuantity: new FormControl({
        value: this.response.requestedQuantity,
        disabled: true,
      }),
      defaultFullDispatchTime: new FormControl({
        value: this.response.confirmedFullDispatchTime,
        disabled: true,
      }),
      confirmedFullDispatchTime: new FormControl({
        value: this.response.confirmedFullDispatchTime,
        disabled: true,
      }),
      activationRequestAccepted: new FormControl({
        value: true,
        disabled: false,
      }),
      reasonForDeclining: new FormControl(
        {
          value: '',
          disabled: true,
        },
        [
          conditionalValidator(
            () => !this.responseFormGroup.get('activationRequestAccepted').value,
            Validators.compose([Validators.required, Validators.minLength(50), Validators.maxLength(200)])
          ),
        ]
      ),
    });
  }

  private initializeFormControlSubscription() {
    this.responseFormGroupValueChange$ = this.responseFormGroup.valueChanges.subscribe((value: unknown) => {
      this.formValuesChanged.emit(value);
      this.formValidationChanged.emit(this.responseFormGroup.valid);
    });
  }

  onCheckboxChange(): void {
    if (!this.activationRequestAccepted.value) {
      this.reasonForDeclining.enable();
    } else {
      this.reasonForDeclining.disable();
      this.reasonForDeclining.setValue('');
    }
  }
}
