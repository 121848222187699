import { Component } from '@angular/core';

import { SessionExpiryService } from '../../services';

@Component({
  selector: 'nexweb-session-expiry-dialog',
  templateUrl: './session-expiry-dialog.component.html',
})
export class SessionExpiryDialogComponent {
  constructor(private sessionExpiry: SessionExpiryService) {}

  get showDialog(): boolean {
    return this.sessionExpiry.showDialog;
  }

  get timerCountDown(): number {
    return this.sessionExpiry.timerCountDown;
  }

  public continueWorking() {
    this.sessionExpiry.continueWorking();
  }

  public logOut() {
    this.sessionExpiry.logOut();
  }
}
