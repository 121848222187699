import { Component, OnInit } from '@angular/core';

import { ConfigStateService } from '@abp/ng.core';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { ActiveUserRoles, AumService } from '@nexweb/shared/data-access/aum';

import { SharedUiComponentService } from '../../services/shared-ui-component.service';

@Component({
  selector: 'nexweb-system-details',
  templateUrl: './system-details.component.html',
  styleUrls: ['./system-details.component.scss'],
  providers: [SharedUiComponentService],
})
export class SystemDetailsComponent implements OnInit {
  public useOnlyActiveRole: boolean;
  public activeRole: Observable<ActiveUserRoles>;
  public serverTimeDisplay$: Observable<string>;
  public currentUser$ = this.configStateService.getOne$('currentUser');

  constructor(
    private aumService: AumService,
    private sharedUiComponentService: SharedUiComponentService,
    private configStateService: ConfigStateService
  ) {}

  ngOnInit() {
    this.sharedUiComponentService.initialise();

    this.useOnlyActiveRole = JSON.parse(this.configStateService.getSetting('NexBase.UseOnlyActiveRole'));

    this.getUserActiveRole();

    this.serverTimeDisplay$ = this.sharedUiComponentService.getServerTimeDisplay$().pipe(map((date) => date));
  }

  private getUserActiveRole() {
    this.activeRole = this.currentUser$.pipe(
      first(),
      switchMap((currentUser) => this.aumService.getActiveUserRoles(currentUser.id)),
      map((roles) => roles.find((role) => role.isCurrentActiveRole))
    );
  }
}
