import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SammApplications, SammModule } from '@nexweb/shared/data-access/samm-enums';
import { DateTimeHelperService } from '@nexweb/shared/date-time-helper';

import { SammApplicationService, SammParameterService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class SammDataAccessFacade {
  constructor(
    private sammApplicationService: SammApplicationService,
    private sammParameterService: SammParameterService,
    private dateTimeHelperService: DateTimeHelperService
  ) {}

  public getParameterValue(
    sammModule: SammModule,
    sammApplication: SammApplications,
    paramName: string
  ): Observable<string> {
    return this.sammParameterService.getParameterValue(sammModule, sammApplication, paramName);
  }

  public getServerTime(): Observable<Date> {
    return this.sammApplicationService
      .getServerTime()
      .pipe(map((serverTime) => this.dateTimeHelperService.utcToServerTzDateTime(serverTime)));
  }
}
