import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

import { BidNotificationInfo, BmDataAccessFacade } from '@nexweb/shared/data-access/bm';

import { RequestExtensionOfActivationPeriod } from '../../../models';
import { RequestExtensionOfActivationPeriodService } from '../../../services/request-extension-of-activation-period.service';

@Component({
  selector: 'nexweb-ext-tso-response',
  templateUrl: './ext-tso-response.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtTsoResponseComponent implements OnInit {
  response$: Observable<RequestExtensionOfActivationPeriod.ExtensionResponse>;
  @Input() bidId: number;
  labels: RequestExtensionOfActivationPeriod.ExtensionResponseLabels;
  dateTimeFormat: string;
  accepted: boolean;
  confirmedFullDispatchTime: Date;
  rejectReason: string;
  concurrencyStamp: string;
  isFormValid = true;

  constructor(
    private requestExtensionActivationPeriodService: RequestExtensionOfActivationPeriodService,
    private bmFacade: BmDataAccessFacade
  ) {}

  ngOnInit(): void {
    this.labels = this.requestExtensionActivationPeriodService.getTsoResponseLabels();
    this.dateTimeFormat = this.requestExtensionActivationPeriodService.getDateTimeFormat();

    this.initialiseData();
  }

  private initialiseData(): void {
    this.response$ = this.bmFacade.getBidNotificationInfo(this.bidId).pipe(
      map((bidNotificationInfo: BidNotificationInfo) => {
        const bidNotificationData = {
          concurrencyStamp: bidNotificationInfo.concurrencyStamp,
          instrument: bidNotificationInfo.instrument,
          requestingTsoPortfolio: bidNotificationInfo.requestingTsoPortfolioName,
          supplyingBspPortfolio: bidNotificationInfo.supplyingBspPortfolioName,
          requestedQuantity: bidNotificationInfo.requestedQuantity
            ? bidNotificationInfo.requestedQuantity.toString()
            : ' ',
          defaultFullDispatchTime: bidNotificationInfo.defaultFullDispatchTime,
          confirmedFullDispatchTime: bidNotificationInfo.requestFullDispatchTime,
          activationRequestAccepted: true,
          reasonForDeclining: bidNotificationInfo.rejectReason,
        } as RequestExtensionOfActivationPeriod.ExtensionResponse;

        return bidNotificationData;
      })
    );
  }

  public formValuesChanged(event): void {
    if (event.confirmedFullDispatchTime) {
      this.confirmedFullDispatchTime = event.confirmedFullDispatchTime.value;
    }

    if (event.activationRequestAccepted instanceof Object) {
      this.accepted = event.activationRequestAccepted.value;
    } else {
      this.accepted = event.activationRequestAccepted;
    }

    if (event.reasonForDeclining) {
      this.rejectReason = event.reasonForDeclining;
    }

    if (event.concurrencyStamp) {
      this.concurrencyStamp = event.concurrencyStamp;
    }
  }

  public formValidationChanged(event): void {
    this.isFormValid = event;
  }
}
