import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { CoreModule } from '@abp/ng.core';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';

import { LOADING_COMPONENTS } from './components';

@NgModule({
  imports: [CommonModule, CoreModule, IndicatorsModule, MatProgressSpinnerModule],
  declarations: [...LOADING_COMPONENTS],
  exports: [...LOADING_COMPONENTS],
})
export class SharedLoadingModule {
  static forRoot(): ModuleWithProviders<SharedLoadingModule> {
    return {
      ngModule: SharedLoadingModule,
    };
  }
}
